/**
 * @file LotForm.tsx
 * @description Provides a form for editing lot details. Includes fields for various lot properties and actions for copying, deleting, and printing lots.
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { deleteLot, updateLot } from "../../store/slices/lotSlice";
import { Box, TextField, Stack, IconButton } from "@mui/material";
import {
  FileCopy as CopyIcon,
  Delete as DeleteIcon,
  Print as PrintIcon,
} from "@mui/icons-material";
import { CopyLotDialog } from "../CopyLotDialog/CopyLotDialog";
import { Lot } from "../../types";

/**
 * Props for the `LotForm` component.
 */
interface LotFormProps {
  lot: Lot;
}

/**
 * `LotForm` Component
 * - Displays a form for editing lot details, including fields like order number, drawing number, and date.
 * - Implements debounced updates for efficient API calls when editing fields.
 * - Provides actions for copying, deleting, and printing the lot.
 *
 * @param lot - The lot data to display and edit.
 * @returns The rendered form for editing the lot.
 */
export const LotForm: React.FC<LotFormProps> = ({ lot }) => {
  const [localLot, setLocalLot] = useState(lot);
  const [debouncedField, setDebouncedField] = useState<keyof Lot | null>(null);
  const [debouncedValue, setDebouncedValue] = useState<string>("");
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  /**
   * Updates the local lot state whenever the `lot` prop changes.
   */
  useEffect(() => {
    setLocalLot(lot);
  }, [lot]);

  /**
   * Debounces field updates to avoid frequent API calls while typing.
   */
  useEffect(() => {
    if (debouncedField !== null) {
      const handler = setTimeout(() => {
        dispatch(
          updateLot({
            lotId: lot.id,
            payload: {
              changes: { [debouncedField]: debouncedValue },
            },
          })
        );
      }, 500);

      return () => clearTimeout(handler);
    }
  }, [debouncedField, debouncedValue, lot.id, dispatch]);

  /**
   * Handles changes to input fields and updates the local state.
   *
   * @param field - The field being updated.
   * @param value - The new value of the field.
   */
  const handleChange = (field: keyof Lot, value: string) => {
    setLocalLot((prev) => ({ ...prev, [field]: value }));
    setDebouncedField(field);
    setDebouncedValue(value);
  };

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
      }}
    >
      <Stack spacing={2}>
        <TextField
          fullWidth
          label="Auftragsnummer"
          placeholder="Nummer"
          id={`lot_lot_${lot.id}`}
          value={localLot.lot}
          onChange={(e) => handleChange("lot", e.target.value)}
        />

        <TextField
          fullWidth
          label="Zeichnungsnummer"
          placeholder="Nummer"
          id={`lot_artnr_${lot.id}`}
          value={localLot.artnr}
          onChange={(e) => handleChange("artnr", e.target.value)}
        />

        <TextField
          fullWidth
          label="Messungen durch"
          placeholder="Name"
          id={`lot_person_${lot.id}`}
          value={localLot.person}
          onChange={(e) => handleChange("person", e.target.value)}
        />

        <TextField
          fullWidth
          label="Datum"
          type="date"
          id={`lot_date_${lot.id}`}
          value={localLot.date}
          onChange={(e) => handleChange("date", e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          fullWidth
          label="Losgröße"
          placeholder="Stück"
          id={`lot_size_${lot.id}`}
          value={localLot.size}
          onChange={(e) => handleChange("size", e.target.value)}
          type="number"
        />

        <TextField
          fullWidth
          label="Kunde/Lieferant"
          placeholder="Firmanname"
          id={`lot_kunde_${lot.id}`}
          value={localLot.kunde}
          onChange={(e) => handleChange("kunde", e.target.value)}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <IconButton
            color="warning"
            onClick={() => setCopyDialogOpen(true)}
            title="Copy lot"
          >
            <CopyIcon />
          </IconButton>
          <CopyLotDialog
            open={copyDialogOpen}
            onClose={() => setCopyDialogOpen(false)}
            lotId={lot.id}
          />
          <IconButton
            color="error"
            onClick={() => dispatch(deleteLot(lot.id))}
            title="Delete lot"
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            color="primary"
            // onClick={() => window.open(`/report/${lot.id}`, "_blank")}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_BASE_URL}/lots/${lot.id}/pdf`,
                "_blank"
              )
            }
            // http://localhost:9091/api/lots/41/pdf
            title="Print report"
          >
            <PrintIcon />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default LotForm;
