// src/hooks/useContextMenu.ts
import { useDispatch } from "react-redux";
import { openContextMenu } from "../store/slices/contextMenuSlice";
import { AppDispatch } from "../store";

const useContextMenu = () => {
  const dispatch = useDispatch<AppDispatch>();

  /**
   * Handles the context menu action for a criteria item.
   *
   * @param event - The mouse event triggering the context menu.
   * @param id - The ID of the criteria for which the context menu is opened.
   * @param type - The type of the context menu item (e.g., "criteria").
   */
  const handleContextMenu = (
    event: React.MouseEvent,
    id: number,
    type: string
  ) => {
    event.preventDefault();
    dispatch(
      openContextMenu({
        id,
        type,
        anchorPosition: { top: event.clientY, left: event.clientX },
      })
    );
  };

  return { handleContextMenu };
};

export default useContextMenu;
