/**
 * @file store/slices/lotSlice.ts
 * @fileoverview Redux slice for managing lots in the application, including fetching, adding, updating, deleting, searching, and copying lots.
 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Lot, LotState } from "../../types";

/**
 * Initial state for the lots slice.
 */
const initialState: LotState = {
  items: [] as Lot[], // List of lots.
  apiItems: [] as Lot[], // List of lots fetched from an API endpoint.
  selectedLotId: null, // ID of the currently selected lot, or null if none is selected.
  loading: false, // Indicates whether a lot-related operation is in progress.
  error: null, // Stores error messages for failed operations, or null if no errors.
};

/**
 * Async thunk to fetch lots from an API based on the page type.
 * @param pageType - The page type determining which API endpoint to fetch from.
 */
export const fetchApiLots = createAsyncThunk(
  "lots/fetchApiLots",
  async (apiEndpoint: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}${apiEndpoint}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch lots");
    }
    return response.json();
  }
);

/**
 * Async thunk to fetch all lots from the main API.
 */
export const fetchLots = createAsyncThunk("lots/fetchLots", async () => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/lots`);
  if (!response.ok) {
    throw new Error("Failed to fetch lots");
  }
  return response.json();
});

/**
 * Async thunk to add a new lot via the API.
 * @param lot - The identifier for the lot to be added.
 */
export const addLot = createAsyncThunk("lots/addLot", async (lot: string) => {
  const response = await fetch(process.env.REACT_APP_BASE_URL + "/lots/add", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ auftr: lot }),
  });
  return response.json();
});

/**
 * Async thunk to update an existing lot via the API.
 * @param lotId - The ID of the lot to update.
 * @param payload - The data to update the lot with.
 */
export const updateLot = createAsyncThunk(
  "lots/updateLot",
  async ({ lotId, payload }: { lotId: number; payload: any }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/lots/${lotId}/update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to update lot");
    }

    return response.json();
  }
);

/**
 * Async thunk to delete a lot via the API.
 * @param lotId - The ID of the lot to delete.
 */
export const deleteLot = createAsyncThunk(
  "lots/deleteLot",
  async (lotId: number) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/lots/${lotId}/delete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to delete lot");
    }

    return response.json();
  }
);

/**
 * Async thunk to search for lots based on a search term.
 * @param searchTerm - The search term to query lots.
 */
export const searchLots = createAsyncThunk(
  "lots/searchLots",
  async (searchTerm: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/lots/search?word=${encodeURIComponent(
        searchTerm
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Search failed");
    }

    const data = await response.json();
    return data;
  }
);

/**
 * Async thunk to copy a lot with a new identifier.
 * @param lotId - The ID of the lot to copy.
 * @param newLotNumber - The identifier for the new copied lot.
 */
export const copyLot = createAsyncThunk(
  "lots/copyLot",
  async ({ lotId, newLotNumber }: { lotId: number; newLotNumber: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/lots/${lotId}/copy`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          copy_lot: lotId,
          new_lot: newLotNumber,
        }),
      }
    );

    return response.json();
  }
);

/**
 * Redux slice for managing the lots state and handling lot-related actions.
 */
const lotSlice = createSlice({
  name: "lots",
  initialState,
  reducers: {
    /**
     * Sets the selected lot by its ID.
     * @param state - The current state of the slice.
     * @param action - The action payload containing the lot ID to select.
     */
    setSelectedLot: (state, action) => {
      state.selectedLotId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLots.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLots.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchLots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch lots";
      })
      //
      .addCase(fetchApiLots.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApiLots.fulfilled, (state, action) => {
        state.loading = false;
        state.apiItems = action.payload;
      })
      .addCase(fetchApiLots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch lots";
      })
      //
      .addCase(addLot.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLot.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        if (action.payload.length > 0) {
          const latestLot = action.payload.reduce((latest: any, current: any) =>
            new Date(latest.change_date) > new Date(current.change_date)
              ? latest
              : current
          );

          state.selectedLotId = latestLot.id;
        }
      })
      .addCase(addLot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to add lot";
      })
      .addCase(deleteLot.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteLot.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
        state.selectedLotId = null;
      })
      .addCase(deleteLot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to delete lot";
      })
      .addCase(updateLot.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLot.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.status === "has-success") {
          const index = state.items.findIndex(
            (lot) => lot.id === action.meta.arg.lotId
          );

          if (index !== -1) {
            state.items[index] = {
              ...state.items[index],
              ...action.meta.arg.payload.changes,
            };
          }
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(updateLot.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload || "Failed to update lot";
      })
      .addCase(searchLots.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchLots.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(searchLots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(copyLot.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(copyLot.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          state.items = action.payload.lots;
        }
      })
      .addCase(copyLot.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to copy lot";
      });
  },
});

export const { setSelectedLot } = lotSlice.actions;
export default lotSlice.reducer;
