/**
 * @file store/slices/contextMenuSlice.ts
 * @fileoverview Redux slice for managing the state of a context menu in the application.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Context menu state interface
 */
interface ContextMenuState {
  id: number | null;
  type: string | null;
  anchorPosition: { top: number; left: number } | null;
}

/**
 * Initial state for the context menu.
 */
const initialState: ContextMenuState = {
  id: null,
  type: null,
  anchorPosition: null,
};

/**
 * Redux slice for context menu state management.
 */
const contextMenuSlice = createSlice({
  name: "contextMenu",
  initialState,
  reducers: {
    /**
     * Opens the context menu with the provided metadata.
     * @param state - The current state of the context menu.
     * @param action - Payload containing the ID, type, and anchor position of the context menu.
     */
    openContextMenu: (
      state,
      action: PayloadAction<{
        id: number;
        type: string;
        anchorPosition: { top: number; left: number };
      }>
    ) => {
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.anchorPosition = action.payload.anchorPosition;
    },
    /**
     * Closes the context menu and resets its state to initial values.
     * @param state - The current state of the context menu.
     */
    closeContextMenu: (state) => {
      state.id = null;
      state.type = null;
      state.anchorPosition = null;
    },
  },
});

export const { openContextMenu, closeContextMenu } = contextMenuSlice.actions;
export default contextMenuSlice.reducer;
