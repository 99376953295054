/**
 * @file LotManagement.tsx
 * @description Provides a component for managing lots, including searching, adding, and selecting lots.
 * Supports both manual and API-driven workflows based on the page configuration.
 */
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Toast from "../Toast/Toast";
import { addLot, fetchApiLots, fetchLots } from "../../store/slices/lotSlice";
import { SNACKBAR_MESSAGES, useSnackbar } from "../../custom/useSnackbar";
import { clearRightPanel } from "../../store/slices/rightPanelSlice";
import { clearValueData } from "../../store/slices/valueSlice";
import { Lot } from "../../types";
import { Page } from "../../types/env";
import { setSelectedLot } from "../../store/slices/lotSlice";
import { PageConfig } from "../../types/pageConfig";

/**
 * Props for the `LotManagement` component.
 */
interface LotManagementProps {
  type: string;
  config: PageConfig;
}

/**
 * `LotManagement` Component
 * - Allows users to manage lots by searching, adding, or selecting them.
 * - Handles both manual and API-driven workflows based on the `type` prop.
 * - Provides feedback using a snackbar for success or error messages.
 *
 * @param type - Specifies whether the page is manual or API-driven.
 * @param config - The page configuration.
 * @returns The rendered lot management component.
 */
const LotManagement: React.FC<LotManagementProps> = ({ type, config }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchTerm, setSearchTerm] = useState("");
  const [newLot, setNewLot] = useState("");
  const { apiItems, loading, items } = useSelector(
    (state: RootState) => state.lots
  );
  const { snackbar, showSnackbar, handleCloseSnackbar } = useSnackbar();

  /**
   * Filters lots based on the search term and page type.
   */
  const filteredLots = useCallback(() => {
    const lotsToFilter = type === Page.MANUAL ? items : apiItems;

    return lotsToFilter.filter(
      (lot) =>
        lot.lot?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lot.kunde?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [type, items, apiItems, searchTerm]);

  /**
   * Checks if a lot with the given lot number already exists.
   * @param lotNumber - The lot number to check.
   * @returns The existing lot if found, otherwise `undefined`.
   */
  const checkLotExists = useCallback(
    (lotNumber: string): Lot | undefined => {
      return items.find((lot) => lot.lot === lotNumber);
    },
    [items]
  );

  /**
   * Handles the selection of a lot from the autocomplete.
   * @param selectedLot - The selected lot.
   */
  const handleLotSelect = async (selectedLot: Lot | null) => {
    if (!selectedLot) return;
    try {
      const existingLot = checkLotExists(selectedLot.lot);
      if (existingLot) {
        dispatch(setSelectedLot(existingLot.id));
      } else {
        await dispatch(addLot(selectedLot.lot)).unwrap();
        showSnackbar(SNACKBAR_MESSAGES.ADD_LOT_SUCCESS, "success");
        if (config.pageMode === Page.API) {
          dispatch(fetchApiLots(config.apiEndpoint));
        } else {
          dispatch(fetchLots());
        }
      }
      setSearchTerm("");
    } catch (error) {
      console.error("Failed to add lot:", error);
    }
  };

  /**
   * Handles adding a new lot.
   */
  const handleAddLot = async () => {
    if (!newLot.trim()) return;

    try {
      await dispatch(addLot(newLot.trim())).unwrap();
      showSnackbar(SNACKBAR_MESSAGES.ADD_LOT_SUCCESS, "success");
      setNewLot("");
      dispatch(clearValueData());
      dispatch(clearRightPanel());
    } catch (error) {
      showSnackbar(SNACKBAR_MESSAGES.ADD_LOT_ERROR, "error");
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
      {type === Page.MANUAL && (
        <TextField
          fullWidth
          value={newLot}
          onChange={(e) => setNewLot(e.target.value)}
          placeholder="Add new lot..."
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleAddLot} edge="end">
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}

      <Autocomplete
        options={filteredLots()}
        getOptionLabel={(option: Lot) =>
          `${option.lot} - ${option.kunde || ""}`
        }
        loading={loading}
        onChange={(_, newValue) => handleLotSelect(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search lots..."
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
          />
        )}
      />

      <Toast
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default LotManagement;
