/**
 * @file store.ts
 * @fileoverview Configures the Redux store with slices for managing application state, including lots, values, right panel, context menu, and devices.
 */
import { configureStore } from "@reduxjs/toolkit";
import lotReducer from "./slices/lotSlice";
import valueReducer from "./slices/valueSlice";
import rightPanelReducer from "./slices/rightPanelSlice";
import contextMenuReducer from "./slices/contextMenuSlice";
import deviceReducer from "./slices/deviceSlice";

/**
 * Configures the Redux store with individual slices for application state.
 * - `lots`: Manages state for lots (LotState).
 * - `values`: Manages state for measurement values (ValueState).
 * - `rightPanel`: Manages state for the right panel (RightPanelState).
 * - `contextMenu`: Manages state for context menu interactions (ContextMenuState).
 * - `devices`: Manages state for devices (DeviceState).
 */
export const store = configureStore({
  reducer: {
    lots: lotReducer,
    values: valueReducer,
    rightPanel: rightPanelReducer,
    contextMenu: contextMenuReducer,
    devices: deviceReducer,
  },
});

/**
 * Type representing the complete state of the Redux store.
 * It is derived from the store's `getState` method.
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * Type representing the Redux dispatch function for the application.
 * It is derived from the store's `dispatch` method.
 */
export type AppDispatch = typeof store.dispatch;
