/**
 * @file MeasurementField.tsx
 * @description A React component for inputting measurement values, validating input, and submitting the data to the store.
 */
import { Button, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { addValue } from "../../store/slices/valueSlice";
import Toast from "../Toast/Toast";
import {
  SNACKBAR_MESSAGES,
  SNACKBAR_TYPES,
  useSnackbar,
} from "../../custom/useSnackbar";
import AddIcon from "@mui/icons-material/Add";

/**
 * `MeasurementField` Component
 * - Provides an input field for adding a measurement value associated with a selected criteria.
 * - Validates the input and displays success or error messages via a toast notification.
 *
 * @returns {React.ReactElement} The rendered component for measurement input.
 */
const MeasurementField: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [measurementValue, setMeasurementValue] = useState("");
  const { snackbar, showSnackbar, handleCloseSnackbar } = useSnackbar();

  const dispatch = useDispatch<AppDispatch>();

  /**
   * Retrieves the ID of the currently selected criteria from the Redux store.
   */
  const selectedCriteriaId = useSelector(
    (state: RootState) => state.rightPanel.selectedCriteriaId
  );

  /**
   * Automatically focuses the input field whenever the component renders or the value changes.
   */
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [measurementValue]);

  /**
   * Handles the form submission event.
   * - Validates the input value.
   * - Dispatches the `addValue` action to add the measurement to the store.
   * - Displays a success or error message using the `Toast` component.
   *
   * @param {React.FormEvent} e - The form submission event.
   */
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!measurementValue) {
      showSnackbar(SNACKBAR_MESSAGES.NO_VALUE_ERROR, SNACKBAR_TYPES.ERROR);
      return;
    }

    try {
      const result = await dispatch(
        addValue({
          criteriaId: Number(selectedCriteriaId),
          value: Number(measurementValue),
        })
      ).unwrap();

      if (result.success) {
        showSnackbar(
          SNACKBAR_MESSAGES.ADD_VALUE_SUCCESS,
          SNACKBAR_TYPES.SUCCESS
        );
        setMeasurementValue("");
      }
    } catch (error) {
      console.log(error, "error");
      showSnackbar(SNACKBAR_MESSAGES.ADD_VALUE_ERROR, SNACKBAR_TYPES.ERROR);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ marginBottom: 16, display: "flex", alignItems: "normal" }}
      >
        <TextField
          fullWidth
          value={measurementValue}
          onChange={(e) => setMeasurementValue(e.target.value)}
          placeholder="zB.: 12,012"
          variant="outlined"
          required={true}
          inputRef={inputRef}
          size={"small"}
        />
        <Button type="submit" variant="contained">
          <AddIcon />
        </Button>
      </form>
      <Toast
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default MeasurementField;
