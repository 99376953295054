/**
 * @file CriteriaPanel.tsx
 * @description Provides a form to add criteria to the selected lot, displaying the lot details and enabling user input for dimension, label, and associated device.
 */
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { addCriteria } from "../../store/slices/rightPanelSlice";
import Toast from "../Toast/Toast";
import {
  SNACKBAR_MESSAGES,
  SNACKBAR_TYPES,
  useSnackbar,
} from "../../custom/useSnackbar";
import { fetchDevices } from "../../store/slices/deviceSlice";
import SelectWithInput from "../SelectWithInput/SelectWithInput";

/**
 * `CriteriaPanel` Component
 * - Displays a form for adding new criteria to the selected lot.
 * - Allows input for the criteria's label, dimension, and associated device.
 * - Shows the selected lot's details.
 * - Provides visual feedback via a toast notification for success or error messages.
 *
 * @returns The rendered criteria panel component.
 */
const CriteriaPanel: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // State for form inputs
  const [dimension, setDimension] = useState("");
  const [label, setLabel] = useState<string>("");
  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);

  // Snackbar state and methods
  const { snackbar, showSnackbar, handleCloseSnackbar } = useSnackbar();

  // Redux state selectors
  const selectedLotId = useSelector(
    (state: RootState) => state.lots.selectedLotId
  );
  const lot = useSelector((state: RootState) =>
    state.lots.items.find((l) => l.id === selectedLotId)
  );
  const { items: devices } = useSelector((state: RootState) => state.devices);

  /**
   * Fetches the available devices when the component is mounted.
   */
  useEffect(() => {
    dispatch(fetchDevices());
  }, [dispatch]);

  /**
   * Handles the form submission for adding a new criteria.
   *
   * @param e - The form submission event.
   */
  const handleAddCriteria = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedLotId || !dimension.trim() || !selectedDevice) return;

    try {
      await dispatch(
        addCriteria({
          lotId: Number(selectedLotId),
          dimension: dimension.trim(),
          deviceId: selectedDevice,
          label: label.trim(),
        })
      ).unwrap();
      showSnackbar(
        SNACKBAR_MESSAGES.ADD_CRITERIA_SUCCES,
        SNACKBAR_TYPES.SUCCESS
      );
      setDimension("");
      setSelectedDevice("");
      setLabel("");
    } catch (error) {
      console.error("Failed to add criteria:", error);
      showSnackbar(SNACKBAR_MESSAGES.ADD_CRITERIA_ERROR, SNACKBAR_TYPES.ERROR);
    }
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        style={{
          border: "1px solid #eee",
          background: "#fbfbfb",
          borderRadius: "10px",
        }}
      >
        <Toolbar>
          <Typography variant="h6">
            <strong>{lot?.lot}</strong> - {lot?.artnr}
          </Typography>

          <Box component="form" onSubmit={handleAddCriteria} sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid size={3}>
                <TextField
                  fullWidth
                  label="Label"
                  placeholder="Pos 1"
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                  style={{ minWidth: "130px" }}
                  size="small"
                />
              </Grid>
              <Grid size={3}>
                <TextField
                  fullWidth
                  label="Maß"
                  placeholder="zB.: Ø3,2 ±0,1"
                  value={dimension}
                  onChange={(e) => setDimension(e.target.value)}
                  style={{ minWidth: "130px" }}
                  size="small"
                />
              </Grid>
              <Grid size={3}>
                <SelectWithInput
                  selectedDevice={selectedDevice}
                  options={devices}
                  setSelectedDevice={setSelectedDevice}
                />
              </Grid>
              <Grid size={3}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!dimension.trim() || !selectedDevice}
                  style={{ minWidth: "100px" }}
                >
                  hinzufügen
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>

      <Toast
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default CriteriaPanel;
