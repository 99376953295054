/**
 * @file CriteriaItem.tsx
 * @description Renders an individual criteria item with details, supporting selection and context menu interactions.
 */
import React from "react";
import { Box, Typography, Chip, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Criteria } from "../../types";

/**
 * Props for the `CriteriaItem` component.
 */
interface CriteriaItemProps {
  criteria: Criteria;
  onClick: () => void;
  isSelected?: boolean;
  onContextMenu: (event: React.MouseEvent) => void;
}

/**
 * Styled `Box` component for the criteria item.
 * - Adds hover effects and visual feedback for selection.
 */
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  padding: theme.spacing(2),
  cursor: "pointer",
  backgroundColor: isSelected ? theme.palette.action.selected : "transparent",
  "&:hover": {
    backgroundColor: isSelected
      ? theme.palette.action.selected
      : theme.palette.action.hover,
    borderRadius: "9px",
  },
  borderRadius: isSelected ? "9px" : "0px",
  display: "flex",
  alignItems: "center",
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

/**
 * `CriteriaItem` Component
 * - Displays the details of a single criteria item, including dimension, equipment ID, and count.
 * - Supports click and context menu interactions for user actions.
 *
 * @param criteria - The criteria data to display.
 * @param onClick - Callback for handling selection.
 * @param isSelected - Indicates if the item is currently selected.
 * @param onContextMenu - Callback for handling context menu actions.
 * @returns The rendered criteria item component.
 */
const CriteriaItem: React.FC<CriteriaItemProps> = ({
  criteria,
  onClick,
  isSelected,
  onContextMenu,
}) => {
  /**
   * Formats the delta value between the dimension and a given value.
   *
   * @param value - The value to compare with the dimension.
   * @returns A string representing the delta with a "+" or "-" prefix.
   */
  const formatDelta = (value: number) => {
    const delta = value - Number(criteria?.dimension);
    if (value === 0) return "0";
    return delta >= 0 ? `+${delta.toFixed(1)}` : delta.toFixed(1);
  };

  return (
    <StyledBox
      onClick={onClick}
      isSelected={isSelected}
      onContextMenu={onContextMenu}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {criteria.bez && (
          <Chip
            label={criteria?.bez}
            size="small"
            sx={{
              mr: 1,
              background: "#cfe7ff",
              color: "#333333",
              fontWeight: "500",
              fontSize: "15px",
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            gap: "2px",
          }}
        >
          <Typography variant="body1" fontSize={18}>
            {criteria?.dimension}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              display="block"
              sx={{ fontSize: "11px", lineHeight: 1 }}
            >
              {formatDelta(criteria?.upper_value)}
            </Typography>

            <Typography
              variant="caption"
              display="block"
              sx={{ fontSize: "12px", lineHeight: 1 }}
            >
              {formatDelta(criteria?.lower_value)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6" sx={{ mx: 2 }}>
          {criteria?.equipment_id}
        </Typography>
        <Badge
          badgeContent={criteria?.anzahl || 0}
          color="primary"
          sx={{ ml: 1 }}
        />
      </Box>
    </StyledBox>
  );
};

export default CriteriaItem;
