/**
 * @file CopyLotDialog.tsx
 * @description A modal dialog for copying an existing lot to a new lot number.
 * Allows the user to input a new lot number and triggers the copy action.
 */
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { copyLot } from "../../store/slices/lotSlice";
import {
  SNACKBAR_MESSAGES,
  SNACKBAR_TYPES,
  useSnackbar,
} from "../../custom/useSnackbar";
import Toast from "../Toast/Toast";

/**
 * Props for the `CopyLotDialog` component.
 */
interface CopyLotDialogProps {
  open: boolean;
  onClose: () => void;
  lotId: number;
}

/**
 * `CopyLotDialog` Component
 * - Displays a dialog for copying a lot with a new lot number.
 * - Handles user input and triggers the `copyLot` action when the user confirms.
 * - Shows a loading state during the operation and displays success or error messages via a toast.
 *
 * @param {CopyLotDialogProps} props - The properties for the dialog.
 */
export const CopyLotDialog: React.FC<CopyLotDialogProps> = ({
  open,
  onClose,
  lotId,
}) => {
  const [newLotNumber, setNewLotNumber] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const { snackbar, showSnackbar, handleCloseSnackbar } = useSnackbar();

  const dispatch = useDispatch<AppDispatch>();

  /**
   * Handles the copy action by dispatching the `copyLot` action.
   * - Validates input.
   * - Displays success or error messages via snackbar.
   * - Resets the form and closes the dialog on success.
   */
  const handleCopy = async () => {
    if (!newLotNumber.trim()) return;

    setLoading(true);
    try {
      const result = await dispatch(
        copyLot({
          lotId,
          newLotNumber: newLotNumber.trim(),
        })
      ).unwrap();
      if (result.success) {
        showSnackbar(
          SNACKBAR_MESSAGES.COPY_LOT_SUCCESS,
          SNACKBAR_TYPES.SUCCESS
        );
        setNewLotNumber("");
        onClose();
      } else {
        showSnackbar(SNACKBAR_MESSAGES.COPY_LOT_ERROR, SNACKBAR_TYPES.ERROR);
      }
    } catch (error) {
      showSnackbar(
        error instanceof Error
          ? error.message
          : SNACKBAR_MESSAGES.COPY_LOT_ERROR,
        SNACKBAR_TYPES.ERROR
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * Resets the input field and closes the dialog.
   */
  const handleClose = () => {
    setNewLotNumber("");
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Enter new lot number for copying</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Lot Number"
            fullWidth
            value={newLotNumber}
            onChange={(e) => setNewLotNumber(e.target.value)}
            disabled={loading}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleCopy}
            variant="contained"
            color="primary"
            disabled={loading || !newLotNumber.trim()}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Copy
          </Button>
        </DialogActions>
      </Dialog>

      <Toast
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};
