/**
 * @file ControlChart.tsx
 * @description Renders a control chart using D3.js to visualize measurement data against specified bounds.
 * Displays grid lines, threshold lines, and data points on an interactive chart.
 */
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";
import { Value } from "../../types";

/**
 * Props for the `ControlChartProps` component.
 */
interface ControlChartProps {
  data: any;
  bounds: {
    min: number;
    max: number;
    nominal: number;
  };
  values: Value[];
}

/**
 * `ControlChart` Component
 * - Uses D3.js to create a control chart for visualizing measurement data.
 * - Includes grid lines, threshold lines, and a line connecting the data points.
 * - Dynamically updates whenever the `data` or `bounds` change.
 *
 * @param {ControlChartProps} props - The properties for the control chart.
 */
const ControlChart: React.FC<ControlChartProps> = ({
  data,
  bounds,
  values,
}) => {
  const chartRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!chartRef.current || !data.length) return;

    // Clear previous chart
    d3.select(chartRef.current).selectAll("*").remove();

    // Get container dimensions
    const container = chartRef.current.parentElement;
    const width = container ? container.clientWidth : 800;
    const height = container ? container.clientHeight : 300;

    // Setup margins
    const margin = { top: 20, right: 20, bottom: 30, left: 50 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    // Create SVG
    const svg = d3
      .select(chartRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create scales
    const xScale = d3
      .scaleLinear()
      .domain([data.length, 1])
      .range([0, chartWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([
        Math.min(bounds.min, d3.min(data, (d: any) => +d.value) || 0) - 0.005,
        Math.max(bounds.max, d3.max(data, (d: any) => +d.value) || 0) + 0.005,
      ])
      .range([chartHeight, 0]);

    // Add grid lines
    svg
      .selectAll("grid-line-y")
      .data(yScale.ticks())
      .enter()
      .append("line")
      .attr("class", "grid-line")
      .attr("x1", 0)
      .attr("x2", chartWidth)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#e0e0e0")
      .attr("stroke-dasharray", "2,2");

    svg
      .selectAll("grid-line-x")
      .data(xScale.ticks())
      .enter()
      .append("line")
      .attr("class", "grid-line")
      .attr("x1", (d) => xScale(d))
      .attr("x2", (d) => xScale(d))
      .attr("y1", 0)
      .attr("y2", chartHeight)
      .attr("stroke", "#e0e0e0")
      .attr("stroke-dasharray", "2,2");

    // Add axes
    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale).ticks(10);

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(xAxis);

    svg.append("g").attr("class", "y-axis").call(yAxis);

    // Add threshold lines
    const thresholds = [bounds.min, bounds.max, bounds.nominal];
    const thresholdColors = ["#ef4444", "#ef4444", "#22c55e"];

    thresholds.forEach((threshold, i) => {
      svg
        .append("line")
        .attr("x1", 0)
        .attr("x2", chartWidth)
        .attr("y1", yScale(threshold))
        .attr("y2", yScale(threshold))
        .attr("stroke", thresholdColors[i])
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "5,5");
    });

    // Create line generator
    const line = d3
      .line<any>()
      .x((d) => xScale(d.index))
      .y((d) => yScale(d.value));

    // Add path
    const dataWithIndex = data.map((d: any, i: any) => ({
      ...d,
      index: data.length - i,
    }));

    svg
      .append("path")
      .datum(dataWithIndex)
      .attr("fill", "none")
      .attr("stroke", "#3b82f6")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Add points
    svg
      .selectAll("circle")
      .data(dataWithIndex)
      .enter()
      .append("circle")
      .attr("cx", (d: any) => xScale(d.index))
      .attr("cy", (d: any) => yScale(d.value))
      .attr("r", 4)
      .attr("fill", "#3b82f6")
      .attr("stroke", "white")
      .attr("stroke-width", 2);
  }, [data, bounds, values]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <svg ref={chartRef} />
    </Box>
  );
};

export default ControlChart;
