/**
 * @file LotsPanel.tsx
 * @description Renders the lots panel, including a sidebar for managing lots and displaying a list of lots.
 * Supports context menu interactions and provides feedback when no lots are available.
 */
import { Box, Paper, Typography } from "@mui/material";
import LotManagement from "../LotManagement/LotManagement";
import LotList from "../LotList/LotList";
import { useState } from "react";
import { Lot } from "../../types";
import { PageConfig } from "../../types/pageConfig";

/**
 * Represents the state of the context menu for lots.
 */
interface ContextMenuState {
  mouseX: number;
  mouseY: number;
  lotId?: number;
}

/**
 * Props for the `LotsPanel` component.
 */
interface LotPanelProps {
  lots: Lot[];
  type: string;
  config: PageConfig;
}

/**
 * `LotsPanel` Component
 * - Displays a sidebar containing the `LotManagement` component and a list of lots.
 * - Handles context menu interactions for lots.
 * - Provides a fallback message when no lots are available.
 *
 * @param lots - Array of lots to display.
 * @param type - Specifies whether the page is manual or API-driven.
 * @param config - Configuration for the page.
 * @returns The rendered lots panel.
 */
const LotsPanel: React.FC<LotPanelProps> = ({ lots, type, config }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null);

  /**
   * Handles the context menu event for a lot.
   * Opens the context menu at the mouse position.
   *
   * @param event - The mouse event triggering the context menu.
   * @param lotId - The ID of the lot associated with the context menu.
   */
  const handleContextMenu = (event: React.MouseEvent, lotId: number) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      lotId,
    });
  };

  return (
    <Box
      id="sidebar-wrapper"
      sx={{
        width: 300,
        borderRight: "1px solid #e3e3e3",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "#ffffff",
          borderBottom: "1px solid #e3e3e3",
          padding: "20px",
        }}
      >
        <LotManagement type={type} config={config} />
      </Box>

      <Box
        id="left"
        sx={{
          flex: 1,
          overflowY: "auto",
          padding: "10px 20px",
          background: "#fbfbfb",
        }}
      >
        {lots ? (
          <LotList lots={lots} onContextMenu={handleContextMenu} type={type} />
        ) : (
          <Paper
            elevation={1}
            sx={{ padding: 2, backgroundColor: "error.main" }}
          >
            <Typography variant="body1" color="white">
              Content not found
            </Typography>
          </Paper>
        )}
      </Box>
    </Box>
  );
};
export default LotsPanel;
