/**
 * @file useSnackbar.ts
 * @description Provides a custom React hook for managing a snackbar state in the application.
 * Includes predefined messages and severities to standardize user notifications.
 */

import { useState } from "react";

/**
 * Predefined messages for snackbar notifications.
 * Includes success, error, and warning messages for various actions.
 */
export const SNACKBAR_MESSAGES = {
  ADD_LOT_SUCCESS: "Lot added successfully.",
  ADD_LOT_ERROR: "Failed to add lot. Please try again.",
  COPY_LOT_SUCCESS: "Lot successfully copied.",
  COPY_LOT_ERROR: "Failed to copy lot.",
  NOT_FOUND_CRITERIA_WARNING: "Criteria not found.",
  ADD_CRITERIA_SUCCES: "Criteria added successfully.",
  ADD_CRITERIA_ERROR: "Failed to add criteria.",
  UPDATE_CRITERIA_SUCCESS: "Criteria update successfully.",
  UPDATE_CRITERIA_ERROR: "Failed to update criteria.",
  DELETE_CRITERIA_SUCCESS: "Criteria deleted successfully.",
  ADD_VALUE_SUCCESS: "Value added successfully.",
  ADD_VALUE_ERROR: "Failed to add value. Please try again.",
  UPDATE_VALUE_ERROR: "Failed to add value.",
  UPDATE_VALUE_SUCCESS: "Value updated successfully.",
  DELETE_VALUE_SUCCESS: "Value deleted successfully.",
  NOT_FOUND_VALUE_WARNING: "Value not found.",
  NO_VALUE_ERROR: "Please enter a value.",
};

/**
 * Predefined severity levels for the snackbar.
 * - `SUCCESS`: Indicates a successful operation.
 * - `WARNING`: Indicates a potential issue or caution.
 * - `ERROR`: Indicates a failure or error.
 * - `INFO`: Indicates informational messages.
 */
export const SNACKBAR_TYPES = {
  SUCCESS: "success" as const,
  WARNING: "warning" as const,
  ERROR: "error" as const,
  INFO: "info" as const,
};

/**
 * Type representing the severity levels for snackbar notifications.
 */
export type SnackbarSeverity =
  (typeof SNACKBAR_TYPES)[keyof typeof SNACKBAR_TYPES];

/**
 * Custom React hook for managing snackbar notifications.
 * Provides state and handlers to display and hide the snackbar.
 */
export const useSnackbar = () => {
  /**
   * State for managing the snackbar.
   * - `open`: Indicates whether the snackbar is visible.
   * - `message`: The message to display in the snackbar.
   * - `severity`: The severity level of the snackbar (e.g., success, error).
   */
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: SnackbarSeverity;
  }>({
    open: false,
    message: "",
    severity: SNACKBAR_TYPES.SUCCESS,
  });

  /**
   * Displays the snackbar with the specified message and severity.
   * @param message - The message to display in the snackbar.
   * @param severity - The severity level of the snackbar.
   */
  const showSnackbar = (message: string, severity: SnackbarSeverity) => {
    setSnackbar({ open: true, message, severity });
  };

  /**
   * Hides the snackbar by setting its `open` state to false.
   */
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return { snackbar, showSnackbar, handleCloseSnackbar };
};
