/**
 * @file SelectWithInput.tsx
 * @description A component that combines a searchable dropdown (select) with the ability to add a new device via an input field and confirmation dialog.
 */

import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  ListSubheader,
} from "@mui/material";
import { Device } from "../../types";
import { addDevice } from "../../store/slices/deviceSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

/**
 * Props for the `SelectWithInput` component.
 */
interface SelectWithInputProps {
  options: Device[] | null;
  selectedDevice: string | null;
  setSelectedDevice: (value: string | null) => void;
}

/**
 * `SelectWithInput` Component
 * - Provides a searchable dropdown to select a device.
 * - Allows users to add a new device via the input field if it doesn't exist.
 * - Displays a confirmation dialog before creating a new device.
 *
 * @param options - List of devices to display.
 * @param selectedDevice - Currently selected device ID.
 * @param setSelectedDevice - Callback to update the selected device.
 * @returns The rendered component.
 */
const SelectWithInput: React.FC<SelectWithInputProps> = ({
  options,
  selectedDevice,
  setSelectedDevice,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Device[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { loading } = useSelector((state: RootState) => state.devices);

  const dispatch = useDispatch<AppDispatch>();

  /**
   * Updates the filtered options when the list of devices changes.
   */
  useEffect(() => {
    setFilteredOptions(options || []);
  }, [options]);

  /**
   * Filters the list of options based on the input value.
   *
   * @param event - The input change event.
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const value = event.target.value;
    setInputValue(value);

    const filtered = options?.filter((option) =>
      option.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered || []);
  };

  /**
   * Handles selection of a device from the dropdown.
   *
   * @param event - The selection change event.
   */
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedDevice(event.target.value);
  };

  /**
   * Handles the `Enter` key to trigger the modal for creating a new device.
   *
   * @param event - The keyboard event.
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (event.key === "Enter" && inputValue.trim()) {
      setIsModalOpen(true);
    }
  };

  /**
   * Handles focusing the input field for immediate typing.
   *
   * @param event - The focus event.
   */
  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  /**
   * Closes the confirmation dialog.
   */
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  /**
   * Creates a new device when confirmed in the modal.
   */
  const handleCreate = async () => {
    try {
      const result = await dispatch(
        addDevice({ name: inputValue.trim() })
      ).unwrap();

      if (result.success && result.device) {
        setSelectedDevice(result.device.id.toString());
        setInputValue("");
      }
    } catch (err) {
      console.error("Failed to create device:", err);
    }

    setIsModalOpen(false);
    setIsSelectOpen(false);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="device-select-label" style={{ top: "-6px" }}>
          Messgerät
        </InputLabel>
        <Select
          open={isSelectOpen}
          onOpen={() => setIsSelectOpen(true)}
          onClose={() => setIsSelectOpen(false)}
          labelId="device-select-label"
          value={selectedDevice || ""}
          onChange={handleSelectChange}
          label="Messgerät"
          style={{ minWidth: "130px" }}
          size="small"
        >
          <ListSubheader style={{ padding: 0 }}>
            <TextField
              inputRef={inputRef}
              placeholder="Type to search or add..."
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={handleInputFocus}
              fullWidth
              size="small"
              variant="outlined"
              onClick={(e) => e.stopPropagation()}
              sx={{
                padding: "4px",
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          </ListSubheader>

          {filteredOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ConfirmDialog
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleCreate}
        title="Confirm Creation"
        message={`Are you sure you want to create a new device: ${inputValue}?`}
        confirmText="Create"
        cancelText="Cancel"
        loading={loading}
      />
    </>
  );
};

export default SelectWithInput;
