/**
 * @file MeasurementValues.tsx
 * @description A React component that displays a list of measurement values with visual indicators for values within or outside defined bounds.
 */
import { Alert, Box, Paper, Typography } from "@mui/material";
import { Criteria, Value } from "../../types";

/**
 * Props for the `MeasurementValues` component.
 */
interface MeasurementValuesProps {
  values: Value[] | null;
  bounds: any;
  onContextMenu: (event: React.MouseEvent, id: number, type: string) => void;
  criteria: Criteria | null;
}

/**
 * `MeasurementValues` Component
 * - Displays a list of measurement values with color-coded indicators for whether values are within acceptable bounds.
 * - Provides a context menu on right-click for individual values.
 * - Highlights if any measurement values are out of bounds with an alert.
 *
 * @param values - The measurement values to display.
 * @param bounds - The boundary limits for acceptable values.
 * @param onContextMenu - Callback for handling context menu events.
 * @param criteria - The associated criteria.
 * @returns The rendered list of measurement values.
 */
const MeasurementValues: React.FC<MeasurementValuesProps> = ({
  values,
  bounds,
  onContextMenu,
  criteria,
}) => {
  return (
    <>
      <Box style={{ display: "flex", flexWrap: "wrap" }}>
        {values &&
          [...values].reverse().map((m: any, i: any) => {
            const numericValue =
              typeof m.value === "string" ? parseFloat(m.value) : m.value;
            const isInBounds =
              numericValue >= bounds.min && numericValue <= bounds.max;

            const displayIndex = values.length - i;
            return (
              <Box
                key={m.id}
                style={{
                  display: "inline-block",
                  whiteSpace: "no-wrap",
                }}
                onContextMenu={(event: any) =>
                  onContextMenu(event, m.id, "value")
                }
              >
                <Paper
                  sx={{
                    p: 1,
                    textAlign: "center",
                    backgroundColor: isInBounds
                      ? "success.light"
                      : "error.light",
                  }}
                >
                  <Typography variant="body2">{displayIndex}:</Typography>
                  <Typography variant="body1">
                    {typeof numericValue === "number" && !isNaN(numericValue)
                      ? numericValue.toFixed(3)
                      : "Invalid"}
                  </Typography>
                </Paper>
              </Box>
            );
          })}
      </Box>

      {values &&
        values.some(
          (m: any) => +m.value < +bounds.min || +m.value > +bounds.max
        ) && (
          <Alert severity="error" sx={{ mt: 2 }}>
            Some measurements are out of bounds!
          </Alert>
        )}
    </>
  );
};

export default MeasurementValues;
