/**
 * @file EditCriteriaModal.tsx
 * @description A modal dialog for editing criteria. Allows users to update dimensions, labels, and associated devices for a specific criterion.
 */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { updateCriteria } from "../../store/slices/rightPanelSlice";
import { Criteria } from "../../types";
import {
  SNACKBAR_MESSAGES,
  SNACKBAR_TYPES,
  useSnackbar,
} from "../../custom/useSnackbar";
import Toast from "../Toast/Toast";

/**
 * Props for the `EditCriteriaModal` component.
 */
interface EditCriteriaModalProps {
  open: boolean;
  onClose: () => void;
  criteria: Criteria | null;
}

/**
 * `EditCriteriaModal` Component
 * - Provides a form to edit and update the selected criterion.
 * - Fields include label, dimension, and associated device.
 * - Displays success or error notifications using a snackbar.
 *
 * @param {EditCriteriaModalProps} props - The properties for the modal.
 */
export const EditCriteriaModal: React.FC<EditCriteriaModalProps> = ({
  open,
  onClose,
  criteria,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const devices = useSelector((state: RootState) => state.rightPanel.devices);
  const [dimension, setDimension] = useState<string>("");
  const [label, setLabel] = useState<string | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<string>("");
  const { snackbar, showSnackbar, handleCloseSnackbar } = useSnackbar();

  /**
   * Prepopulates the form fields when the modal opens with the current criteria values.
   */
  useEffect(() => {
    if (criteria) {
      const formattedDimension = criteria.dimension
        ? `${criteria.dimension}`
        : criteria.dimension.toString();
      setDimension(formattedDimension);
      setSelectedDevice(criteria.equipment_id);
      setLabel(criteria.bez || null);
    }
  }, [criteria]);

  /**
   * Handles form submission to update the criterion.
   *
   * - Dispatches the `updateCriteria` action.
   * - Displays a success or error message based on the result.
   * - Closes the modal upon success.
   *
   * @param {React.FormEvent} e - The form submission event.
   */
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!criteria || !dimension.trim() || !selectedDevice) return;

    try {
      await dispatch(
        updateCriteria({
          id: criteria.id,
          dimension: dimension.trim(),
          deviceId: selectedDevice,
        })
      ).unwrap();

      showSnackbar(
        SNACKBAR_MESSAGES.UPDATE_CRITERIA_SUCCESS,
        SNACKBAR_TYPES.SUCCESS
      );
      onClose();
    } catch (error) {
      console.error("Failed to update criteria:", error);
      showSnackbar(
        SNACKBAR_MESSAGES.UPDATE_CRITERIA_ERROR,
        SNACKBAR_TYPES.ERROR
      );
    }
  };

  /**
   * Resets the form fields and closes the modal.
   */
  const handleClose = () => {
    setDimension("");
    setSelectedDevice("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Edit Criteria</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Label"
                placeholder="Pos 1"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Maß"
                placeholder="zB.: Ø3,2 ±0,1"
                value={dimension}
                onChange={(e) => setDimension(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Messgerät</InputLabel>
                <Select
                  value={selectedDevice}
                  onChange={(e) => setSelectedDevice(e.target.value)}
                  label="Messgerät"
                >
                  {devices.map((device) => (
                    <MenuItem key={device.id} value={device.id}>
                      {device.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!dimension.trim() || !selectedDevice}
          >
            Update
          </Button>
        </DialogActions>
      </form>

      <Toast
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </Dialog>
  );
};
