/**
 * Enumeration of valid page types in the application.
 * - `API`: Represents an API-driven page type.
 * - `MANUAL`: Represents a manually configured page type.
 */
export enum Page {
  API = "API",
  MANUAL = "manual",
}

/**
 * Determines the page type based on the given environment variable.
 * If the environment variable is invalid or undefined, the default page type is returned.
 *
 * @param envValue - The environment variable value to check.
 * @param defaultType - The default page type to return if the environment value is invalid or undefined.
 * @returns A valid `Page` type.
 */
export function getPageType(
  envValue: string | undefined,
  defaultType: Page = Page.MANUAL
): Page {
  const VALID_PAGE_TYPES = Object.values(Page);
  return VALID_PAGE_TYPES.includes(envValue as Page)
    ? (envValue as Page)
    : defaultType;
}

/**
 * Page type for the page, derived from the `REACT_APP_SPECIFIC` environment variable.
 * Defaults to `MANUAL` if the variable is undefined or invalid.
 */
export const INCOMING_PAGE_TYPE =
  getPageType(process.env.REACT_APP_INCOMING) || "manual";
export const FINAL_PAGE_TYPE =
  getPageType(process.env.REACT_APP_FINAL) || "manual";
export const REPAIR_PAGE_TYPE =
  getPageType(process.env.REACT_APP_REPAIR) || "manual";
