/**
 * @file LotList.tsx
 * @description Renders a list of lots and manages their expanded state.
 * Supports context menu interactions for individual lots and integrates with Redux state for the selected lot.
 */
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Lot } from "../../types";
import LotItem from "../LotItem/LotItem";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

/**
 * Props for the `LotList` component.
 */
interface LotListProps {
  lots: Lot[];
  onContextMenu: (event: React.MouseEvent, lotId: number) => void;
  type: string;
}

/**
 * `LotList` Component
 * - Displays a list of lots and manages their expanded state.
 * - Updates the expanded state based on the selected lot in the Redux store.
 * - Passes down context menu and expansion handlers to individual `LotItem` components.
 *
 * @param lots - Array of lots to display.
 * @param onContextMenu - Callback for context menu actions.
 * @param type - Page type (manual or API-driven).
 * @returns The rendered list of lots.
 */
const LotList: React.FC<LotListProps> = ({ lots, onContextMenu, type }) => {
  const [expandedId, setExpandedId] = useState<number | null>(
    lots[0]?.id || null
  ); // Local state for managing the expanded lot ID.

  const { selectedLotId } = useSelector((state: RootState) => state.lots);

  /**
   * Toggles the expansion state of a lot.
   * Expands the clicked lot and collapses others.
   *
   * @param lotId - The ID of the lot to expand or collapse.
   */
  const handleExpansionChange = (lotId: number) => {
    setExpandedId(expandedId === lotId ? null : lotId);
  };

  /**
   * Updates the expanded lot ID when the selected lot in the Redux store changes.
   */
  useEffect(() => {
    if (selectedLotId) {
      setExpandedId(selectedLotId);
    }
  }, [selectedLotId]);

  return (
    <Box sx={{ width: "100%", paddingTop: "20px" }}>
      {lots.map((lot) => (
        <LotItem
          key={lot.id}
          lot={lot}
          isExpanded={expandedId === lot.id}
          onContextMenu={onContextMenu}
          onExpansionChange={() => handleExpansionChange(lot.id)}
          type={type}
        />
      ))}
    </Box>
  );
};
export default LotList;
