/**
 * @file CriteriaManagement.tsx
 * @description Manages the criteria panel, list, and measurement chart for a selected lot.
 * Fetches and displays criteria data associated with the currently selected lot.
 */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useEffect } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CriteriaPanel from "../CriteriaPanel/CriteriaPanel";
import CriteriaList from "../CriteriaList/CriteriaList";
import MeasurementChart from "../MeasurementChart/MeasurementChart";
import {
  fetchCriteria,
  setSelectedCriteria,
} from "../../store/slices/rightPanelSlice";

/**
 * `CriteriaManagement` Component
 * - Manages and displays criteria and related measurement data for a selected lot.
 * - Fetches criteria data when a new lot is selected.
 * - Displays a criteria panel, a list of criteria, and a measurement chart.
 *
 * @returns The rendered criteria management UI.
 */
const CriteriaManagement: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedLotId = useSelector(
    (state: RootState) => state.lots.selectedLotId
  );

  /**
   * Effect to fetch criteria for the selected lot and reset the selected criteria.
   */
  useEffect(() => {
    if (selectedLotId) {
      dispatch(fetchCriteria(selectedLotId)).unwrap();
      dispatch(setSelectedCriteria(null));
    }
  }, [selectedLotId, dispatch]);

  if (!selectedLotId) return null;

  return (
    <Box sx={{ p: 2 }}>
      <CriteriaPanel />
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid size={4}>
          <CriteriaList />
        </Grid>
        <Grid size={8}>
          <MeasurementChart />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CriteriaManagement;
