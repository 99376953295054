/**
 * @file index.tsx
 * @description Entry point for the React application. Configures the application root, integrates Redux store using the `Provider`, and renders the `App` component.
 */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";

// Create the root React DOM container.
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

/**
 * Renders the React application into the root DOM element.
 * - Wraps the application in the Redux `Provider` to supply the store.
 * - Uses `React.StrictMode` for highlighting potential problems during development.
 */
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
