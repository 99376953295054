/**
 * @file CustomTabPanel.tsx
 * @description A component for rendering the content of a tab panel in conjunction with a tab-based navigation system.
 */

import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/**
 * `CustomTabPanel` Component
 * - Displays the content associated with the currently selected tab.
 * - Hides the content of other tabs.
 *
 * @param {TabPanelProps} props - The properties for the tab panel.
 * @returns {React.ReactElement | null} The rendered tab panel or `null` if not active.
 */
const CustomTabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`chart-tabpanel-${index}`}
      aria-labelledby={`chart-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
};
export default CustomTabPanel;
