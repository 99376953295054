/**
 * @file App.tsx
 * @description Main application component that sets up routing, navigation, and global components like the context menu.
 * Integrates the `Router` for handling application routes and renders pages dynamically based on configuration.
 */
import "./App.css";
import ContextMenu from "./components/ContextMenu/ContextMenu";
import Navbar from "./components/Navigation/NavBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { pagesConfig } from "./types/pageConfig";
import InspectionPage from "./pages/InspectionPage";

/**
 * The main application component.
 * - Uses `BrowserRouter` to enable routing.
 * - Renders the `Navbar` at the top of the page.
 * - Dynamically generates routes for the inspection pages based on `pagesConfig`.
 * - Includes a `ContextMenu` for right-click actions.
 *
 * @returns The rendered application.
 */
function App() {
  return (
    <Router>
      <div className="container">
        <Navbar />
        <Routes>
          {Object.values(pagesConfig).map((config) => (
            <Route
              key={config.type}
              path={config.path}
              element={<InspectionPage config={config} />}
            />
          ))}
        </Routes>
        <ContextMenu />
      </div>
    </Router>
  );
}

export default App;
