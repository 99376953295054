/**
 * @file Toast.tsx
 * @description A reusable toast notification component built with Material-UI, providing visual feedback for various actions.
 */
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";

/**
 * Props for the `Toast` component.
 */
interface ToastProps {
  open: boolean;
  message: string;
  severity?: "success" | "info" | "warning" | "error";
  autoHideDuration?: number;
  onClose?: (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => void;
}

/**
 * `Toast` Component
 * - Displays a brief, auto-dismissing notification with customizable severity and duration.
 * - Built with Material-UI's `Snackbar` and `Alert` components.
 *
 * @param open - Visibility of the toast.
 * @param message - Text message displayed in the toast.
 * @param severity - Feedback severity type (`success`, `info`, `warning`, or `error`).
 * @param autoHideDuration - Auto-close duration in milliseconds.
 * @param onClose - Callback triggered when the toast is closed.
 * @returns The rendered toast notification component.
 */
const Toast: React.FC<ToastProps> = ({
  open,
  message,
  severity = "info",
  autoHideDuration = 6000,
  onClose,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={onClose} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};
export default Toast;
