/**
 * @file EditValueModal.tsx
 * @description Modal component to edit a measurement value.
 */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { updateValue } from "../../store/slices/valueSlice";
import { Value } from "../../types";
import Toast from "../Toast/Toast";
import {
  SNACKBAR_MESSAGES,
  SNACKBAR_TYPES,
  useSnackbar,
} from "../../custom/useSnackbar";

/**
 * Props for the `EditValueModal` component.
 */
interface EditValueModalProps {
  open: boolean;
  onClose: () => void;
  value: Value | null;
}

/**
 * `EditValueModal` Component
 * - Provides a form to edit a specific measurement value.
 * - Displays a snackbar notification upon successful or failed updates.
 *
 * @param {EditValueModalProps} props - The properties for the component.
 */
export const EditValueModal: React.FC<EditValueModalProps> = ({
  open,
  onClose,
  value,
}) => {
  const [measurementValue, setMeasurementValue] = useState("");
  const { snackbar, showSnackbar, handleCloseSnackbar } = useSnackbar();
  const dispatch = useDispatch<AppDispatch>();

  /**
   * Populates the input field with the current measurement value when the modal opens.
   */
  useEffect(() => {
    if (value) {
      setMeasurementValue(value.value.toString());
    }
  }, [value]);

  /**
   * Handles form submission to update the measurement value.
   *
   * - Dispatches the `updateValue` action.
   * - Displays a success or error snackbar message based on the result.
   * - Closes the modal after a short delay upon success.
   *
   * @param {React.FormEvent} e - The form submission event.
   */
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!value || !measurementValue) {
      showSnackbar(SNACKBAR_MESSAGES.NO_VALUE_ERROR, SNACKBAR_TYPES.ERROR);
      return;
    }

    try {
      await dispatch(
        updateValue({
          id: value.id,
          value: Number(measurementValue),
        })
      ).unwrap();

      showSnackbar(
        SNACKBAR_MESSAGES.UPDATE_VALUE_SUCCESS,
        SNACKBAR_TYPES.SUCCESS
      );

      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      console.error("Failed to update value:", error);
      showSnackbar(SNACKBAR_MESSAGES.UPDATE_VALUE_ERROR, SNACKBAR_TYPES.ERROR);
    }
  };

  /**
   * Resets the input field and closes the modal.
   */
  const handleClose = () => {
    setMeasurementValue("");
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Edit Measurement Value</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              value={measurementValue}
              onChange={(e) => setMeasurementValue(e.target.value)}
              placeholder="zB.: 12,012"
              variant="outlined"
              required
              sx={{ mt: 2 }}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!measurementValue.trim()}
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Toast
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};
