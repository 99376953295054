/**
 * @file LotView.tsx
 * @description Renders a read-only view for displaying lot details, with a print action to generate a PDF report.
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { Box, TextField, Stack, IconButton } from "@mui/material";
import { Print as PrintIcon } from "@mui/icons-material";
import { Lot } from "../../types";
import { updateLot } from "../../store/slices/lotSlice";

/**
 * Props for the `LotView` component.
 */
interface LotViewProps {
  lot: Lot;
}

const readOnlyStyle = {
  pointerEvents: "none",
  "& .MuiOutlinedInput-root": {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
};

/**
 * `LotView` Component
 * - Displays a read-only view of a lot's details using Material-UI's `TextField` component.
 * - Allows for a print action that opens a PDF report in a new tab.
 * - Supports debounced updates for specific editable fields.
 *
 * @param lot - The lot data to display in the view.
 * @returns The rendered view for the lot.
 */
export const LotView: React.FC<LotViewProps> = ({ lot }) => {
  const [localLot, setLocalLot] = useState(lot); // Local state for managing lot details.
  const [debouncedField, setDebouncedField] = useState<keyof Lot | null>(null); // Field being debounced for updates.
  const [debouncedValue, setDebouncedValue] = useState<string>(""); // Value of the debounced field.
  const dispatch = useDispatch<AppDispatch>();

  /**
   * Syncs the local lot state with the prop whenever it changes.
   */
  useEffect(() => {
    setLocalLot(lot);
  }, [lot]);

  /**
   * Debounces updates to editable fields, reducing API calls while typing.
   */
  useEffect(() => {
    if (debouncedField !== null) {
      const handler = setTimeout(() => {
        dispatch(
          updateLot({
            lotId: lot.id,
            payload: {
              changes: { [debouncedField]: debouncedValue },
            },
          })
        );
      }, 500);

      return () => clearTimeout(handler);
    }
  }, [debouncedField, debouncedValue, lot.id, dispatch]);

  /**
   * Handles changes to specific fields and updates the local state.
   *
   * @param field - The field being updated.
   * @param value - The new value of the field.
   */
  const handleChange = (field: keyof Lot, value: string) => {
    setLocalLot((prev) => ({ ...prev, [field]: value }));
    setDebouncedField(field);
    setDebouncedValue(value);
  };

  return (
    <Box component="form" sx={{ width: "100%" }}>
      <Stack spacing={2}>
        <TextField
          fullWidth
          label="Auftragsnummer"
          placeholder="Nummer"
          id={`lot_lot_${lot.id}`}
          value={localLot.lot}
          onChange={(e) => handleChange("lot", e.target.value)}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
          sx={readOnlyStyle}
        />

        <TextField
          fullWidth
          label="Zeichnungsnummer"
          placeholder="Nummer"
          id={`lot_artnr_${lot.id}`}
          value={localLot.artnr}
          //   onChange={(e) => handleChange("artnr", e.target.value)}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
          sx={readOnlyStyle}
        />

        <TextField
          fullWidth
          label="Messungen durch"
          placeholder="Name"
          id={`lot_person_${lot.id}`}
          value={localLot.person}
          onChange={(e) => handleChange("person", e.target.value)}
        />

        <TextField
          fullWidth
          label="Datum"
          type="date"
          id={`lot_date_${lot.id}`}
          value={localLot.date}
          onChange={(e) => handleChange("date", e.target.value)}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
          sx={readOnlyStyle}
        />

        <TextField
          fullWidth
          label="Losgröße"
          placeholder="Stück"
          id={`lot_size_${lot.id}`}
          value={localLot.size}
          onChange={(e) => handleChange("size", e.target.value)}
          type="number"
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
          sx={readOnlyStyle}
        />

        <TextField
          fullWidth
          label="Kunde/Lieferant"
          placeholder="Firmanname"
          id={`lot_kunde_${lot.id}`}
          value={localLot.kunde}
          onChange={(e) => handleChange("kunde", e.target.value)}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
          sx={readOnlyStyle}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <IconButton
            color="primary"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_BASE_URL}/lots/${lot.id}/pdf`,
                "_blank"
              )
            }
            title="Print report"
          >
            <PrintIcon />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default LotView;
