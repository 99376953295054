/**
 * @file ContextMenu.tsx
 * @description Implements a context menu for performing actions such as editing or deleting criteria or values.
 * Supports modals for editing and confirmation dialogs for deletion with feedback via a snackbar.
 */
import React, { useCallback, useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { closeContextMenu } from "../../store/slices/contextMenuSlice";
import {
  deleteCriteria,
  setSelectedCriteria,
} from "../../store/slices/rightPanelSlice";
import { deleteValue } from "../../store/slices/valueSlice";
import { EditCriteriaModal } from "../EditCriteriaModal/EditCriteriaModal";
import { EditValueModal } from "../EditValueModal/EditValueModal";
import { Criteria, Value } from "../../types";
import {
  SNACKBAR_MESSAGES,
  SNACKBAR_TYPES,
  useSnackbar,
} from "../../custom/useSnackbar";
import Toast from "../Toast/Toast";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

/**
 * Enum for context menu item types.
 */
enum ContextMenuType {
  CRITERIA = "criteria",
  VALUE = "value",
}

/**
 * `ContextMenu` Component
 * - Displays a context menu for criteria and values.
 * - Handles edit and delete actions with modals and confirmation dialogs.
 * - Provides user feedback through a snackbar for errors and success messages.
 *
 * @returns The rendered context menu component.
 */
const ContextMenu: React.FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false); // State for confirmation dialog visibility.
  const [dialogType, setDialogType] = useState<string | null>(null); // Type of the dialog (criteria or value).
  const [dialogId, setDialogId] = useState<number | null>(null); // ID of the item to delete.
  const [message, setMessage] = useState<string>(""); // Message for the confirmation dialog.
  const [openEditCriteriaModal, setOpenEditCriteriaModal] =
    useState<boolean>(false); // State for the criteria edit modal.
  const [openEditValueModal, setOpenEditValueModal] = useState<boolean>(false); // State for the value edit modal.
  const [selectedCriteria, setCriteria] = useState<Criteria | null>(null); // Currently selected criteria for editing.
  const [selectedValue, setSelectedValue] = useState<Value | null>(null); // Currently selected value for editing.

  const dispatch = useDispatch<AppDispatch>();
  const { id, type, anchorPosition } = useSelector(
    (state: RootState) => state.contextMenu
  );
  const { criteriaList } = useSelector((state: RootState) => state.rightPanel);
  const { items: values } = useSelector((state: RootState) => state.values);
  const { snackbar, showSnackbar, handleCloseSnackbar } = useSnackbar();

  /**
   * Closes the context menu.
   */
  const handleClose = useCallback(() => {
    dispatch(closeContextMenu());
  }, [dispatch]);

  /**
   * Handles the edit action for criteria or values.
   */
  const handleEdit = () => {
    if (type && id) {
      switch (type) {
        case ContextMenuType.CRITERIA:
          const criteria = criteriaList.find((c) => c.id === id);
          if (criteria) {
            setCriteria(criteria);
            setOpenEditCriteriaModal(true);
          } else {
            showSnackbar(
              SNACKBAR_MESSAGES.NOT_FOUND_CRITERIA_WARNING,
              SNACKBAR_TYPES.WARNING
            );
          }
          break;
        case ContextMenuType.VALUE:
          const value = values.find((v) => v.id === id);
          if (value) {
            setSelectedValue(value);
            setOpenEditValueModal(true);
          } else {
            showSnackbar(
              SNACKBAR_MESSAGES.NOT_FOUND_VALUE_WARNING,
              SNACKBAR_TYPES.WARNING
            );
          }
          break;
        default:
          return showSnackbar("Type no correct", SNACKBAR_TYPES.WARNING);
      }
    }

    handleClose();
  };

  /**
   * Handles the delete action with confirmation.
   */
  const handleDelete = async () => {
    if (!dialogId || !dialogType) {
      showSnackbar("Invalid type or ID for deletion", SNACKBAR_TYPES.WARNING);
      handleCloseDialog();
      return;
    }

    try {
      switch (dialogType) {
        case ContextMenuType.CRITERIA:
          await dispatch(deleteCriteria(dialogId)).unwrap();
          showSnackbar(
            SNACKBAR_MESSAGES.DELETE_CRITERIA_SUCCESS,
            SNACKBAR_TYPES.SUCCESS
          );
          dispatch(setSelectedCriteria(null));
          break;

        case ContextMenuType.VALUE:
          const result = await dispatch(deleteValue(dialogId)).unwrap();
          if (result) {
            showSnackbar(
              SNACKBAR_MESSAGES.DELETE_VALUE_SUCCESS,
              SNACKBAR_TYPES.SUCCESS
            );
          }
          break;

        default:
          showSnackbar(
            `Unknown type "${dialogType}" for deletion`,
            SNACKBAR_TYPES.WARNING
          );
          break;
      }
    } catch (error) {
      showSnackbar(
        `Failed to delete ${dialogType}. ${error}`,
        SNACKBAR_TYPES.ERROR
      );
      console.error(
        `Failed to delete ${dialogType} with ID ${dialogId}:`,
        error
      );
    }

    handleCloseDialog();
  };

  /**
   * Opens the confirmation dialog for deletion.
   */
  const handleOpenDialog = () => {
    if (!type || !id) {
      console.error("Invalid type or ID for opening the dialog");
      return;
    }

    setDialogType(type);
    setDialogId(id);
    setOpenDialog(true);
    handleClose();
  };

  /**
   * Closes the confirmation dialog.
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogType(null);
    setDialogId(null);
  };

  const handleEditCriteriaModalClose = () => {
    setOpenEditCriteriaModal(false);
    setCriteria(null);
  };

  const handleEditValueModalClose = () => {
    setOpenEditValueModal(false);
    setSelectedValue(null);
  };

  useEffect(() => {
    const handleClickOutside = () => {
      handleClose();
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClose]);

  /**
   * Updates the confirmation message based on the selected item type.
   */
  useEffect(() => {
    if (dialogType === ContextMenuType.CRITERIA) {
      const foundCriteria = criteriaList.find((c) => c.id === dialogId);
      const criteria = foundCriteria?.bez
        ? foundCriteria?.bez
        : `${foundCriteria?.dimension} ${foundCriteria?.equipment_id}`;
      setMessage(`Are you sure you want to delete: ${criteria}? `);
    }
    if (dialogType === ContextMenuType.VALUE) {
      const valueIndex = values.findIndex((c) => c.id === dialogId) + 1;
      setMessage(`Are you sure you want to delete ${valueIndex} value? `);
    }
  }, [dialogType, dialogId, criteriaList, values]);

  return (
    <>
      <Menu
        open={Boolean(anchorPosition)}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPosition
            ? { top: anchorPosition.top, left: anchorPosition.left }
            : undefined
        }
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleOpenDialog}>Delete</MenuItem>
      </Menu>

      {selectedCriteria && (
        <EditCriteriaModal
          open={openEditCriteriaModal}
          onClose={handleEditCriteriaModalClose}
          criteria={selectedCriteria}
        />
      )}

      {selectedValue && (
        <EditValueModal
          open={openEditValueModal}
          onClose={handleEditValueModalClose}
          value={selectedValue}
        />
      )}

      <ConfirmDialog
        isOpen={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleDelete}
        title="Confirm Deletion"
        message={message}
        confirmText="Delete"
        cancelText="Cancel"
        type="dialog"
      />

      <Toast
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default ContextMenu;
