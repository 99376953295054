/**
 * @file pageConfig.ts
 * @description Defines configuration for different page types in the application, including their paths, titles, API endpoints, and modes.
 */

import { getPageType, Page } from "../types/env";

/**
 * Enumeration of page types in the application.
 * - `INCOMING`: Represents the incoming inspection page.
 * - `FINAL`: Represents the final inspection page.
 * - `REPAIR`: Represents the repair page.
 */

export enum PageType {
  INCOMING = "incoming",
  FINAL = "final",
  REPAIR = "repair",
}

/**
 * Interface defining the configuration for a page in the application.
 */
export interface PageConfig {
  type: PageType;
  path: string;
  title: string;
  apiEndpoint: string;
  pageMode: Page;
}

/**
 * Configuration object for all available pages in the application.
 * Maps each `PageType` to its corresponding `PageConfig`.
 */
export const pagesConfig: Record<PageType, PageConfig> = {
  [PageType.INCOMING]: {
    type: PageType.INCOMING,
    path: "/",
    title: "Wareneingang",
    apiEndpoint: "/incomingLots",
    pageMode: getPageType(process.env.REACT_APP_INCOMING),
  },
  [PageType.FINAL]: {
    type: PageType.FINAL,
    path: "/final",
    title: "Endkontrolle",
    apiEndpoint: "/finalLots",
    pageMode: getPageType(process.env.REACT_APP_FINAL),
  },
  [PageType.REPAIR]: {
    type: PageType.REPAIR,
    path: "/repair",
    title: "Reparatur/Reklamation",
    apiEndpoint: "/repairLots",
    pageMode: getPageType(process.env.REACT_APP_REPAIR),
  },
};
