/**
 * @file store/slices/valueSlice.ts
 * @fileoverview Redux slice for managing measurement values, statistics, distributions, criteria, and related data in the application.
 */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  Value,
  Statistics,
  Distribution,
  Criteria,
  ProcessCapability,
} from "../../types";

/**
 * Initial state for the values slice.
 */
const initialState = {
  items: [] as Value[], // List of measurement values.
  statistics: null as Statistics | null, // Statistical data associated with values.
  distribution: null as Distribution[] | null, // Distribution data for values.
  criteria: null as Criteria | null, // Criteria information associated with values.
  loading: false, // Indicates whether a values-related operation is in progress.
  error: null as string | null, // Stores error messages for failed operations.
  processCapability: null as ProcessCapability | null, // Process capability metrics.
  steadyControl: null as any, // Data related to steady control
  chartData: null as any, // Data for chart visualization
};

/**
 * Async thunk to fetch values for a specific criteria.
 * @param criteriaId - The ID of the criteria to fetch values for.
 * @returns A promise resolving to the fetched data.
 */
export const fetchValues = createAsyncThunk(
  "values/fetchValues",
  async (criteriaId: number) => {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + `/values/${criteriaId}`
    );
    return response.json();
  }
);

/**
 * Async thunk to add a new value.
 * @param criteriaId - The ID of the criteria to associate the value with.
 * @param value - The value to add.
 * @param rejectWithValue - Callback to handle rejection with a specific value.
 * @returns A promise resolving to the updated data or an error.
 */
export const addValue = createAsyncThunk(
  "values/addValue",
  async (
    { criteriaId, value }: { criteriaId: number; value: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + `/values/${criteriaId}/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ id: criteriaId, value }),
        }
      );

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.error || "Failed to add value");
      }

      if (data.values?.length === 1) {
        return {
          success: true,
          values: data.values,
          statistics: {
            measured: 1,
            min: data.values[0].value,
            max: data.values[0].value,
            cpk: 0,
            cp: 0,
          },
          distribution: [],
          chartData: [],
          steadyControl: null,
        };
      }

      return data;
    } catch (error: any) {
      console.error(error, "error");
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

/**
 * Async thunk to update an existing value.
 * @param id - The ID of the value to update.
 * @param value - The updated value.
 * @returns A promise resolving to the updated data.
 */
export const updateValue = createAsyncThunk(
  "values/updateValue",
  async ({ id, value }: { id: number; value: number }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/values/${id}/update`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ value }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update value");
    }

    return response.json();
  }
);

/**
 * Async thunk to delete a value.
 * @param id - The ID of the value to delete.
 * @param rejectWithValue - Callback to handle rejection with a specific value.
 * @returns A promise resolving to the updated data or an error.
 */
export const deleteValue = createAsyncThunk(
  "values/deleteValue",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/values/${id}/delete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to delete value");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Redux slice for managing values and associated data.
 */
const valueSlice = createSlice({
  name: "values",
  initialState,
  reducers: {
    /**
     * Sets the value-related data in the state.
     * @param state - The current state of the slice.
     * @param action - The payload containing value-related data.
     */
    setValues: (
      state,
      action: PayloadAction<{
        values: Value[];
        statistics: Statistics;
        distribution: Distribution[];
        criteria: Criteria;
        processCapability: ProcessCapability;
        steadyControl: any;
        chartData: any;
      }>
    ) => {
      state.items = action.payload.values;
      state.criteria = action.payload.criteria;
      state.processCapability = action.payload.processCapability;
      state.items = action.payload.values;
      state.statistics = action.payload.statistics;
      state.distribution = action.payload.distribution;
      state.criteria = action.payload.criteria;
      state.chartData = action.payload.chartData;
    },
    /**
     * Clears all value-related data from the state.
     * @param state - The current state of the slice.
     */
    clearValueData: (state) => {
      state.criteria = null;
      state.items = [];
      state.statistics = null;
      state.distribution = [];
      state.chartData = [];
    },
    /**
     * Sets the loading state.
     * @param state - The current state of the slice.
     * @param action - The payload containing the new loading state.
     */
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    /**
     * Sets an error message in the state.
     * @param state - The current state of the slice.
     * @param action - The payload containing the error message.
     */
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchValues.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchValues.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload.values;
        state.statistics = action.payload.statistics;
        state.distribution = action.payload.distribution;
        state.criteria = action.payload.criteria;
        state.steadyControl = action.payload.steadyControl;
        state.chartData = action.payload.chartData;
      })
      .addCase(fetchValues.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(addValue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addValue.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.items = action.payload.values;
          state.statistics = action.payload.statistics;
          state.distribution = action.payload.distribution;
          state.chartData = action.payload.chartData;
          state.error = null;
        }
      })
      .addCase(addValue.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Failed to add value";
      })
      .addCase(updateValue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateValue.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload.values;
        state.statistics = action.payload.statistics;
        state.distribution = action.payload.distribution;
        state.criteria = action.payload.criteria;
        state.chartData = action.payload.chartData;
        state.steadyControl = action.payload.steadyControl;
      })
      .addCase(updateValue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      })
      .addCase(deleteValue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteValue.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload.values;
        state.statistics = action.payload.statistics;
        state.distribution = action.payload.distribution;
        state.chartData = action.payload.chartData;
        state.steadyControl = action.payload.steadyControl;
      })
      .addCase(deleteValue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearValueData } = valueSlice.actions;
export default valueSlice.reducer;
