/**
 * @file MeasurementChart.tsx
 * @description A comprehensive chart and data visualization component for displaying measurement statistics, histograms, control charts, and measurement values for a selected criteria.
 */
import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Paper, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchValues } from "../../store/slices/valueSlice";
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  ReferenceLine,
} from "recharts";
import MeasurementStatistics from "../MeasurementStatistics/MeasurementStatistics";
import ControlChart from "../ControlChart/ControlChart";
import MeasurementValues from "../MeasurementValues/MeasurementValues";
import MeasurementField from "../MeasurementField/MeasurementField";
import CustomTabPanel from "../CustomTabPanel/CustomTabPanel";
import ChartTabs from "../ChartTabs/ChartTabs";
import useContextMenu from "../../custom/useContextMenu";

/**
 * `MeasurementChart` Component
 * - Visualizes measurement data for a selected criteria, including histograms, control charts, and statistics.
 * - Provides interactive tabs for switching between different chart views.
 * - Supports context menu actions for measurement values.
 *
 * @returns The rendered measurement chart and related components.
 */
const MeasurementChart: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [tabValue, setTabValue] = useState(0);

  // Redux selectors for criteria, statistics, and values
  const {
    criteria,
    statistics,
    items: values,
  } = useSelector((state: RootState) => state.values);

  const { handleContextMenu } = useContextMenu();

  const selectedCriteriaId = useSelector(
    (state: RootState) => state.rightPanel.selectedCriteriaId
  );

  /**
   * Fetches measurement values for the selected criteria.
   */
  useEffect(() => {
    if (selectedCriteriaId) {
      dispatch(fetchValues(selectedCriteriaId));
    }
  }, [selectedCriteriaId, dispatch]);

  /**
   * Handles tab changes for switching between histogram and control chart views.
   *
   * @param event - The event that triggered the tab change.
   * @param newValue - The new tab index.
   */
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  /**
   * Calculates bounds for the criteria, including minimum, maximum, and nominal values.
   */
  const bounds = useMemo(
    () => ({
      min: criteria?.lower_value || 0,
      max: criteria?.upper_value || 0,
      nominal: criteria?.dimension || 0,
    }),
    [criteria]
  );

  /**
   * Processes histogram data for visualization, calculating bins and fitting distributions.
   */
  const histogramData = React.useMemo(() => {
    if (!values || values.length === 0) return [];
    const measurementValues = values.map((item) => Number(item.value));

    const numberOfBins = 10; // Adjust as needed
    const minValue = Math.min(...measurementValues);
    const maxValue = Math.max(...measurementValues);
    const binWidth = (maxValue - minValue) / numberOfBins;

    const mean =
      measurementValues.reduce((a, b) => a + b, 0) / measurementValues.length;
    const stdDev = Math.sqrt(
      measurementValues.reduce((a, b) => a + Math.pow(b - mean, 2), 0) /
        measurementValues.length
    );

    const bins = Array.from({ length: numberOfBins }, (_, i) => ({
      x0: minValue + i * binWidth,
      x1: minValue + (i + 1) * binWidth,
      count: 0,
    }));

    measurementValues.forEach((value) => {
      const binIndex = Math.min(
        Math.floor((value - minValue) / binWidth),
        numberOfBins - 1
      );
      if (binIndex >= 0 && binIndex < bins.length) {
        bins[binIndex].count += 1;
      }
    });

    const maxCount = Math.max(...bins.map((bin) => bin.count));
    const normalizationFactor = 1 / maxCount;

    return bins.map((bin) => {
      const x = (bin.x0 + bin.x1) / 2;

      const expected =
        (1 / (bounds.nominal * Math.sqrt(2 * Math.PI))) *
        Math.exp(
          -0.5 *
            Math.pow((x - bounds.nominal) / ((bounds.max - bounds.min) / 6), 2)
        );

      const actual =
        (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
        Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));

      return {
        x,
        count: bin.count,
        expected,
        actual: actual * normalizationFactor,
      };
    });
  }, [values, bounds]);

  const maxHistogramY =
    histogramData && Math.max(...histogramData.map((d) => d.count));

  return (
    <>
      {" "}
      {selectedCriteriaId && (
        <Box
          sx={{ p: 3 }}
          style={{ border: "1px solid #eee", borderRadius: "10px" }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {criteria?.dimension} (min {criteria?.lower_value} / max{" "}
            {criteria?.upper_value})
          </Typography>

          <Divider sx={{ mb: 1 }} />

          <MeasurementField />

          <Box sx={{ width: "100%" }}>
            <Box>
              <ChartTabs
                tabValue={tabValue}
                handleTabChange={handleTabChange}
              />
            </Box>

            {values?.length > 1 ? (
              <Box
                style={{
                  display: "flex",
                  gap: "20px",
                  width: "100%",
                  alignItems: "start",
                }}
              >
                <Box style={{ width: "80%" }}>
                  <CustomTabPanel value={tabValue} index={0}>
                    <Paper sx={{ p: 1, mb: 3, height: 250 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                          data={histogramData}
                          margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="x"
                            type="number"
                            domain={[
                              Number(criteria?.lower_value ?? 0),
                              Number(criteria?.upper_value ?? 0),
                            ]}
                            ticks={[
                              Number(criteria?.lower_value ?? 0),
                              Number(criteria?.upper_value ?? 0),
                            ]}
                            tickFormatter={(val) => val.toFixed(3)}
                          />
                          <YAxis
                            yAxisId="left"
                            domain={[0, maxHistogramY * 1.1]}
                            tickFormatter={(value) => value.toFixed(0)}
                          />
                          <YAxis
                            yAxisId="right"
                            orientation="right"
                            domain={[0, 1.1]}
                            tickFormatter={(value) => value.toFixed(3)}
                          />
                          <Tooltip
                            formatter={(value: number, name: string) =>
                              name === "Histogram"
                                ? [`Count: ${value.toFixed(0)}`, name]
                                : [value.toFixed(3), name]
                            }
                            labelFormatter={(label) =>
                              `Value: ${Number(label).toFixed(3)}`
                            }
                          />

                          <ReferenceLine
                            x={Number(criteria?.lower_value ?? 0)}
                            stroke="#808080"
                            strokeDasharray="3 3"
                            yAxisId="right"
                            label={{
                              value: "Min",
                              position: "top",
                              fill: "#808080",
                            }}
                          />
                          <ReferenceLine
                            x={Number(criteria?.upper_value ?? 0)}
                            stroke="#808080"
                            yAxisId="right"
                            strokeDasharray="3 3"
                            label={{
                              value: "Max",
                              position: "top",
                              fill: "#808080",
                            }}
                          />

                          {histogramData && histogramData.length > 0 && (
                            <Bar
                              dataKey="count"
                              data={histogramData}
                              yAxisId="left"
                              fill="#8884d8"
                              name="Histogram"
                              barSize={10}
                              opacity={0.6}
                            />
                          )}
                          <Area
                            dataKey="expected"
                            yAxisId="right"
                            stroke="#000"
                            fill="#000"
                            fillOpacity={0.1}
                            name="Expected"
                          />
                          <Area
                            dataKey="actual"
                            yAxisId="right"
                            stroke="#ef4444"
                            fill="#ef4444"
                            fillOpacity={0.3}
                            name="Actual"
                          />
                        </ComposedChart>
                      </ResponsiveContainer>
                    </Paper>
                  </CustomTabPanel>

                  <CustomTabPanel value={tabValue} index={1}>
                    <Paper sx={{ p: 1, mb: 3, height: 250 }}>
                      <ControlChart
                        data={values}
                        bounds={bounds}
                        values={values}
                      />
                    </Paper>
                  </CustomTabPanel>
                </Box>
                <MeasurementStatistics statistics={statistics} />
              </Box>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  width: "100%",
                  display: "block",
                  padding: "20px 0",
                }}
              >
                {values?.length === 0
                  ? "No data available"
                  : values?.length === 1
                  ? "Add more values to see the graph"
                  : "Not enough data to display the graph"}
              </Typography>
            )}
          </Box>

          {values && (
            <MeasurementValues
              criteria={criteria}
              bounds={bounds}
              values={values}
              onContextMenu={handleContextMenu}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default MeasurementChart;
