/**
 * @file store/slices/rightPanelSlice.ts
 * @fileoverview Redux slice for managing the state of the right panel in the application.
 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RightPanelState } from "../../types";

/**
 * Initial state for the right panel slice.
 */
const initialState: RightPanelState = {
  lot: null, // Information about the currently selected lot.
  criteriaList: [], // List of criteria associated with the selected lot.
  devices: [], // List of devices associated with the selected lot or criteria.
  loading: false, // Indicates whether a right panel operation is in progress.
  error: null, // Stores error messages for failed operations, or null if no errors.
  selectedCriteriaId: null, // ID of the currently selected criteria, or null if none is selected.
};

/**
 * Async thunk to fetch criteria for a specific lot.
 * @param lotId - The ID of the lot to fetch criteria for.
 */
export const fetchCriteria = createAsyncThunk(
  "rightPanel/fetchCriteria",
  async (lotId: number) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/criterias/${lotId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch right panel data");
    }
    return response.json();
  }
);

/**
 * Async thunk to add a new criteria.
 * @param data - The data for the new criteria, including lot ID, dimension, device ID, and label.
 */
export const addCriteria = createAsyncThunk(
  "rightPanel/addCriteria",
  async (data: {
    lotId: number;
    dimension: any;
    deviceId: any;
    label: string;
  }) => {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "/criterias/add",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to add criteria");
    }
    return response.json();
  }
);

/**
 * Async thunk to update an existing criteria.
 * @param data - The data for updating a criteria, including its ID, dimension, and device ID.
 */
export const updateCriteria = createAsyncThunk(
  "rightPanel/updateCriteria",
  async (data: { id: number; dimension: string; deviceId: string }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/criterias/${data.id}/update`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dimension: data.dimension,
          deviceId: data.deviceId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update criteria");
    }

    const result = await response.json();
    return result;
  }
);

/**
 * Async thunk to delete a criteria.
 * @param criteriaId - The ID of the criteria to delete.
 * @param rejectWithValue - Callback to handle rejection with a specific value.
 */
export const deleteCriteria = createAsyncThunk(
  "rightPanel/deleteCriteria",
  async (criteriaId: number, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + `/criterias/${criteriaId}/delete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete criteria");
      }

      return response.json();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Redux slice for managing the right panel state and handling associated actions.
 */
const rightPanelSlice = createSlice({
  name: "rightPanel",
  initialState,
  reducers: {
    /**
     * Sets the selected criteria by its ID.
     * @param state - The current state of the slice.
     * @param action - The action payload containing the criteria ID to select.
     */
    setSelectedCriteria: (state, action) => {
      state.selectedCriteriaId = action.payload;
    },
    /**
     * Clears the right panel state, resetting criteria and selected criteria.
     * @param state - The current state of the slice.
     */
    clearRightPanel: (state) => {
      state.criteriaList = [];
      state.selectedCriteriaId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCriteria.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.lot = action.payload.lot;
        state.criteriaList = action.payload.criterias || [];
        state.devices = action.payload.devices || [];
      })
      .addCase(fetchCriteria.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch data";
      })
      .addCase(addCriteria.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.criteriaList = action.payload.criterias;
      })
      .addCase(addCriteria.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateCriteria.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.criteriaList = action.payload.criterias;
      })
      .addCase(updateCriteria.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteCriteria.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.criteriaList = action.payload.criterias || [];
      })
      .addCase(deleteCriteria.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setSelectedCriteria, clearRightPanel } = rightPanelSlice.actions;
export default rightPanelSlice.reducer;
