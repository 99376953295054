/**
 * @file ChartTabs.tsx
 * @description A component for rendering tabbed navigation with icons for chart visualization.
 */
import { Tab, Tabs } from "@mui/material";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TimelineIcon from "@mui/icons-material/Timeline";

/**
 * Generates accessibility properties for tabs.
 *
 * @param {number} index - The index of the tab.
 * @returns {object} Accessibility attributes for the tab.
 */
function a11yProps(index: number) {
  return {
    id: `chart-tab-${index}`,
    "aria-controls": `chart-tabpanel-${index}`,
  };
}

/**
 * `ChartTabs` Component
 * - Displays tabbed navigation for switching between different chart views.
 * - Uses Material-UI `Tabs` and `Tab` components.
 * - Includes icons for visual identification of tabs.
 *
 * @param {number} tabValue - The current selected tab index.
 * @param {function} handleTabChange - A callback function to handle tab change events.
 * @returns {React.ReactElement} The rendered tab component.
 */
const ChartTabs: React.FC<{
  /**
   * The currently selected tab index.
   */
  tabValue: number;
  /**
   * Callback function triggered when the tab selection changes.
   *
   * @param {React.SyntheticEvent} event - The event object.
   * @param {number} newValue - The new tab index.
   */
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}> = ({ tabValue, handleTabChange }) => (
  <Tabs
    value={tabValue}
    onChange={handleTabChange}
    aria-label="chart tabs"
    variant="fullWidth"
    sx={{
      ".MuiTabs-flexContainer": {
        minHeight: "20px",
        paddingBottom: "10px",
        borderBottom: "1px solid #eee",
      },
      ".MuiTabs-indicator": {
        marginBottom: "12px",
      },
    }}
  >
    <Tab
      icon={<ShowChartIcon />}
      iconPosition="start"
      label="Karte"
      {...a11yProps(0)}
      sx={{
        minHeight: "25px",
        padding: "0px 12px",
      }}
    />
    <Tab
      icon={<TimelineIcon />}
      iconPosition="start"
      label="Control"
      {...a11yProps(1)}
      sx={{
        minHeight: "25px",
        padding: "0px 12px",
      }}
    />
  </Tabs>
);

export default ChartTabs;
