/**
 * @file store/slices/deviceSlice.ts
 * @fileoverview Redux slice for managing devices in the application, including fetching, adding, updating, deleting, and searching for devices.
 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Device, DeviceState } from "../../types";

/**
 * Initial state for the devices slice.
 */
const initialState: DeviceState = {
  items: [] as Device[], // List of devices.
  selectedDeviceId: null, // ID of the currently selected device, or null if none is selected.
  loading: false, // Indicates whether a device-related operation is in progress.
  error: null, // Stores error messages for failed operations, or null if no errors.
};

/**
 * Async thunk to fetch all devices from the API.
 */
export const fetchDevices = createAsyncThunk(
  "devices/fetchDevices",
  async () => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + "/devices");
    return response.json();
  }
);

/**
 * Async thunk to add a new device via the API.
 * @param device - The device data to add, containing at least a `name` property.
 */
export const addDevice = createAsyncThunk(
  "devices/addDevice",
  async (device: { name: string }, { rejectWithValue }) => {
    try {
      console.log("Sending request with data:", device);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/devices/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(device),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(
          data.error || `HTTP error! status: ${response.status}`
        );
      }

      if (!data.success) {
        return rejectWithValue(data.error || "Operation failed");
      }

      return data;
    } catch (error: any) {
      console.error("Network or parsing error:", error);
      return rejectWithValue(error.message || "Network error");
    }
  }
);

/**
 * Async thunk to update an existing device via the API.
 * @param deviceId - The ID of the device to update.
 * @param payload - The data to update the device with.
 */
export const updateDevice = createAsyncThunk(
  "devices/updateDevice",
  async ({ deviceId, payload }: { deviceId: number; payload: any }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/devices/${deviceId}/update`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update device");
    }

    return response.json();
  }
);

/**
 * Async thunk to delete a device via the API.
 * @param deviceId - The ID of the device to delete.
 */
export const deleteDevice = createAsyncThunk(
  "devices/deleteDevice",
  async (deviceId: number) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/devices/${deviceId}/delete`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to delete device");
    }

    return response.json();
  }
);

/**
 * Async thunk to search devices by a search term.
 * @param searchTerm - The search term to query devices.
 */
export const searchDevices = createAsyncThunk(
  "devices/searchDevices",
  async (searchTerm: string) => {
    const response = await fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }/devices/search?word=${encodeURIComponent(searchTerm)}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.ok) {
      throw new Error("Search failed");
    }

    return response.json();
  }
);

/**
 * Redux slice for managing the device state and handling device-related actions.
 */
const deviceSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    /**
     * Sets the selected device by its ID.
     * @param state - The current state of the slice.
     * @param action - The action payload containing the device ID to select.
     */
    setSelectedDevice: (state, action) => {
      state.selectedDeviceId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDevices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch devices";
      })
      .addCase(addDevice.pending, (state) => {
        state.loading = true;
      })
      .addCase(addDevice.fulfilled, (state, action) => {
        state.loading = false;
        state.items.push(action.payload);
        if (action.payload.success && action.payload.device) {
          state.items = action.payload.devices;
          state.selectedDeviceId = action.payload.device.id;
        }
      })
      .addCase(addDevice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to add device";
      })
      .addCase(deleteDevice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        state.loading = false;
        // state.items = state.items.filter(
        //   (device) => device.id !== action.meta.arg
        // ); // Remove deleted device
        state.selectedDeviceId = null;
      })
      .addCase(deleteDevice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to delete device";
      })
      .addCase(updateDevice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDevice.fulfilled, (state, action) => {
        state.loading = false;

        // const index = state.items.findIndex(
        //   (device) => device.id === action.meta.arg.deviceId
        // );

        // if (index !== -1) {
        //   state.items[index] = {
        //     ...state.items[index],
        //     ...action.meta.arg.payload, // Update the specific device
        //   };
        // }
      })
      .addCase(updateDevice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update device";
      })
      .addCase(searchDevices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchDevices.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(searchDevices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Search failed";
      });
  },
});

export const { setSelectedDevice } = deviceSlice.actions;
export default deviceSlice.reducer;
