import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { pagesConfig } from "../../types/pageConfig";

/**
 * `Navbar` Component
 * - Renders a responsive navigation bar with links based on `pagesConfig`.
 * - Highlights the active link based on the current route.
 * - Includes a mobile-friendly menu for smaller screens.
 *
 * @returns The rendered navigation bar.
 */

const Navbar: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Opens the mobile navigation menu.
   * @param event - The mouse event triggering the menu.
   */
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the mobile navigation menu.
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /**
   * Navigates to the specified path and closes the mobile menu.
   * @param path - The path to navigate to.
   */
  const handleNavigate = (path: string) => {
    navigate(path);
    handleMenuClose();
  };

  /**
   * Checks if a path matches the current route.
   * @param path - The path to check.
   * @returns `true` if the path matches the current route, otherwise `false`.
   */
  const isActivePath = (path: string): boolean => {
    return location.pathname === path;
  };

  /**
   * Generates navigation buttons from `pagesConfig`.
   */
  const navButtons = Object.values(pagesConfig).map((page) => ({
    path: page.path,
    label: page.title,
    isActive: isActivePath(page.path),
  }));

  return (
    <AppBar
      position="static"
      color="default"
      sx={{
        height: "60px",
        flexShrink: 0,
        backgroundColor: "#f1f1f1",
        borderBottom: "1px solid #e3e3e3",
        boxShadow: "none",
        gap: "20px",
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          fontWeight={600}
          sx={{ color: "#1976d2", flexGrow: { xs: 1, sm: 0 } }}
        >
          Kammerer Medical Group
        </Typography>

        {/* Desktop Navigation */}
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexGrow: 1,
            gap: 2,
            ml: 4,
          }}
        >
          {navButtons.map((button) => (
            <Button
              key={button.path}
              color="inherit"
              onClick={() => handleNavigate(button.path)}
              sx={{
                backgroundColor: button.isActive
                  ? "rgba(25, 118, 210, 0.08)"
                  : "transparent",
                "&:hover": {
                  backgroundColor: button.isActive
                    ? "rgba(25, 118, 210, 0.12)"
                    : "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              {button.label}
            </Button>
          ))}
        </Box>

        <IconButton
          color="inherit"
          sx={{ visibility: "hidden" }}
          id="load_symbol"
        >
          <RefreshIcon />
        </IconButton>

        {/* Mobile Navigation Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          {navButtons.map((button) => (
            <MenuItem
              key={button.path}
              onClick={() => handleNavigate(button.path)}
              sx={{
                backgroundColor: button.isActive
                  ? "rgba(25, 118, 210, 0.08)"
                  : "transparent",
              }}
            >
              {button.label}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
