/**
 * @file LotItem.tsx
 * @description Renders an expandable accordion item for an individual lot.
 * Supports both manual and API-driven workflows, allowing the display of forms or views based on the page type.
 * Includes contextual menu handling and visual feedback based on the lot's status.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Lot } from "../../types";
import { Page } from "../../types/env";
import LotForm from "../LotForm/LotForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { setSelectedLot } from "../../store/slices/lotSlice";
import LotView from "../LotView/LotView";

/**
 * Props for the `LotItem` component.
 */
interface LotItemProps {
  lot: Lot;
  isExpanded: boolean;
  onExpansionChange: () => void;
  onContextMenu: (event: React.MouseEvent, lotId: number) => void;
  type: string;
}

/**
 * `LotItem` Component
 * - Renders an accordion item for a lot with dynamic content based on the page type.
 * - Displays a form for manual pages and a read-only view for API-driven pages.
 * - Highlights the lot's status with background colors.
 * - Supports context menu interactions for additional actions.
 *
 * @param lot - The lot data to display.
 * @param isExpanded - Indicates whether the lot item is expanded.
 * @param onExpansionChange - Callback for toggling the expansion state.
 * @param onContextMenu - Callback for handling context menu actions.
 * @param type - Page type (manual or API-driven).
 * @param config - Page configuration.
 * @returns The rendered accordion item for the lot.
 */
const LotItem: React.FC<LotItemProps> = ({
  lot,
  isExpanded,
  onExpansionChange,
  onContextMenu,
  type,
}) => {
  // Fetch the latest state of the lot from the Redux store.
  const updatedLot = useSelector((state: RootState) =>
    state.lots.items.find((item: Lot) => item.id === lot.id)
  );

  const dispatch = useDispatch<AppDispatch>();

  /**
   * Handles the click event to select the lot and toggle its expansion state.
   */
  const handleClick = () => {
    dispatch(setSelectedLot(lot.id));
    onExpansionChange();
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleClick}
      sx={{
        width: "100%",
        background:
          lot.status === "Measured"
            ? "#bde4bd"
            : lot.status === "Not measured"
            ? "#ffd3d3"
            : "white",
      }}
      data-lot-id={lot.id}
      onContextMenu={(e) => onContextMenu(e, lot.id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`heading_${lot.id}`}
        aria-controls={`collapse_${lot.id}`}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            "& .MuiTypography-root": {
              color: isExpanded ? "primary.main" : "text.primary",
            },
          }}
        >
          <Typography
            id={`lot_title_${lot.id}`}
            sx={{ fontWeight: "bold", mr: 1 }}
          >
            {lot.lot}
          </Typography>
          <Typography variant="body1">- {lot.artnr}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {type === Page.MANUAL && <LotForm lot={updatedLot || lot} />}
        {type === Page.API && <LotView lot={updatedLot || lot} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default LotItem;
