/**
 * @file InspectionPage.tsx
 * @description Renders an inspection page based on the provided configuration.
 * This page displays a list of lots, manages criteria for the selected lot, and handles related state and interactions.
 */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import {
  fetchLots,
  setSelectedLot,
  fetchApiLots,
} from "../store/slices/lotSlice";
import { Lot } from "../types";
import LotsPanel from "../components/LotsPanel/LotsPanel";
import CriteriaManagement from "../components/CriteriaManagement/CriteriaManagement";
import { PageConfig } from "../types/pageConfig";

export enum Page {
  API = "API",
  MANUAL = "manual",
}

/**
 * Props for the `InspectionPage` component.
 */
interface InspectionPageProps {
  /**
   * Configuration for the page, including type, path, title, and API endpoint.
   */
  config: PageConfig;
}

/**
 * InspectionPage Component
 * - Displays lots based on the provided page configuration.
 * - Fetches lots on mount and selects the latest lot for "incoming" pages.
 * - Manages and displays criteria for the selected lot.
 *
 * @param config - Page configuration object.
 * @returns The rendered inspection page.
 */
/**
 * InspectionPage Component
 * - Displays lots based on the provided page configuration.
 * - Fetches lots or API lots based on the page type on mount.
 * - Manages and displays criteria for the selected lot.
 *
 * @param config - Page configuration object.
 * @returns The rendered inspection page.
 */
const InspectionPage: React.FC<InspectionPageProps> = ({ config }) => {
  const [localLots, setLocalLots] = useState<Lot[]>([]); // Local state for managing lots.
  const dispatch = useDispatch<AppDispatch>();
  const {
    items: lots,
    apiItems,
    selectedLotId,
  } = useSelector((state: RootState) => state.lots);

  /**
   * Fetches lots or API lots when the component mounts, based on page type.
   */
  useEffect(() => {
    if (config.pageMode === Page.API) {
      console.log();
      dispatch(fetchApiLots(config.apiEndpoint));
    } else {
      dispatch(fetchLots());
    }
  }, [dispatch, config, config.pageMode]);

  /**
   * Automatically selects the latest lot for "incoming" pages.
   */
  useEffect(() => {
    const relevantLots = config.pageMode === Page.API ? apiItems : lots;

    if (relevantLots.length > 0) {
      const latestLot = [...relevantLots].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      )[0];
      dispatch(setSelectedLot(latestLot.id));
    }
  }, [lots, apiItems, dispatch, config.pageMode]);

  /**
   * Updates local lots when the relevant global lots state changes.
   */
  useEffect(() => {
    const relevantLots = config.pageMode === Page.API ? apiItems : lots;
    setLocalLots(relevantLots);
  }, [lots, apiItems, config.pageMode]);

  return (
    <Box className="main-content">
      <LotsPanel lots={localLots} type={config.pageMode} config={config} />

      <Box
        style={{ width: "100%", margin: "0 auto", padding: "20px" }}
        className="content"
      >
        {selectedLotId && <CriteriaManagement />}
      </Box>
    </Box>
  );
};

export default InspectionPage;
