/**
 * @file MeasurementStatistics.tsx
 * @description A React component that displays statistical data for measurements in a tabular format.
 */
import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Statistics } from "../../types";

/**
 * Props for the `MeasurementStatistics` component.
 */
interface MeasurementStatisticsProps {
  statistics: Statistics | null;
}

/**
 * `MeasurementStatistics` Component
 * - Displays measurement statistics in a clean and readable table format.
 * - Handles numeric data with fixed decimal formatting for non-integer values.
 *
 * @param {Statistics | null} statistics - The statistical data to be displayed.
 * @returns {React.ReactElement} The rendered statistics table.
 */
const MeasurementStatistics: React.FC<MeasurementStatisticsProps> = ({
  statistics,
}) => {
  return (
    <TableContainer
      component={Box}
      sx={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        overflow: "hidden",
        width: "fit-content",
        marginTop: "16px",
      }}
    >
      <Table size="small">
        <TableBody>
          {statistics &&
            Object.entries(statistics).map(([key, value]) => (
              <TableRow key={key}>
                {/* Label Cell */}
                <TableCell
                  sx={{
                    backgroundColor: "#f9f9f9",
                    fontWeight: "bold",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  <Typography variant="body2" color="textSecondary">
                    {key}
                  </Typography>
                </TableCell>
                {/* Value Cell */}
                <TableCell>
                  <Typography variant="body1">
                    {typeof value === "number" && !isNaN(value)
                      ? key === "anzahl" || Number.isInteger(value)
                        ? value
                        : value.toFixed(3)
                      : "N/A"}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MeasurementStatistics;
