/**
 * @file CriteriaList.tsx
 * @description Renders a list of criteria items, providing selection and context menu interactions. Displays an alert if no criteria are found.
 */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { setSelectedCriteria } from "../../store/slices/rightPanelSlice";
import { Box, Alert } from "@mui/material";
import CriteriaItem from "../CriteriaItem/CriteriaItem";
import useContextMenu from "../../custom/useContextMenu";

/**
 * `CriteriaList` Component
 * - Displays a list of criteria items.
 * - Provides functionality to select a criteria and open a context menu for additional actions.
 * - Shows an informational alert if no criteria are available.
 *
 * @returns The rendered list of criteria or an informational message if the list is empty.
 */
const CriteriaList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { criteriaList, selectedCriteriaId } = useSelector(
    (state: RootState) => state.rightPanel
  );
  const { handleContextMenu } = useContextMenu();

  if (!criteriaList.length) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="info">Keine Kriterien gefunden.</Alert>
      </Box>
    );
  }

  return (
    <Box style={{ border: "1px solid #eee", borderRadius: "10px" }}>
      {criteriaList &&
        criteriaList.map((criteria) => (
          <CriteriaItem
            key={criteria.id}
            criteria={criteria}
            onClick={() => dispatch(setSelectedCriteria(criteria.id))}
            isSelected={criteria.id === selectedCriteriaId}
            onContextMenu={(event) =>
              handleContextMenu(event, criteria.id, "criteria")
            }
          />
        ))}
    </Box>
  );
};

export default CriteriaList;
